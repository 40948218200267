import { cn } from "@/lib/cn";

type TaglineProps = {
  text?: string;
  className?: string;
};

const Tagline = ({ text, ...rest }: TaglineProps) => {
  return (
    <div
      className={cn(
        "text-base uppercase tracking-wider font-semibold text-brand-secondary dark:text-brand-primary",
        rest.className
      )}
    >
      {text}
    </div>
  );
};

export { Tagline };
