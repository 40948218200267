import type { ComponentPropsWithoutRef, ElementType } from "react";
import { cn } from "@/lib/cn";

type HeadlineProps<TElementType extends ElementType> =
  ComponentPropsWithoutRef<TElementType> & {
    title: string;
    as?: Extract<TElementType, "h1" | "h2" | "h3" | "h4">;
  };

const Headline = <TElementType extends ElementType>({
  title,
  as,
  ...rest
}: HeadlineProps<TElementType>) => {
  const Element = as ?? "h1";

  return (
    <Element
      className={cn(
        "break-words hyphens-auto dark:text-gray-50",
        {
          ["text-4xl font-bold tracking-tight"]: as === "h1",
          ["text-3xl font-semibold"]: as === "h2",
          ["text-xl font-medium"]: as === "h3",
          ["text-lg font-bold text-gray-900"]: as === "h4",
        },
        rest.className
      )}
    >
      {title}
    </Element>
  );
};

export { Headline };
