"use client";

import { cn } from "@/lib/cn";
import { track } from "@vercel/analytics";
import Link from "next/link";
import type { ComponentProps } from "react";
import { useFormState } from "react-dom";

import { SubmitButton } from "@/components/form/SubmitButton";
import type { FormState } from "@/lib/types/form";

type EmailFormProps = {
  action: (_prevState: any, formData: FormData) => Promise<FormState | void>;
  label?: string;
  placeholder?: string;
  link?: string;
} & Omit<ComponentProps<"form">, "action">;

const EmailForm = ({
  action,
  label = "Anmelden",
  placeholder = "E-Mail Adresse",
  link,
  ...rest
}: EmailFormProps) => {
  const [state, formAction] = useFormState(action, {});

  return (
    <form action={formAction} className={cn("mt-10", rest.className)}>
      <div className="mx-auto max-w-md flex gap-x-4">
        <label htmlFor="email" className="sr-only">
          E-Mail
        </label>
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-brand-secondary focus:ring-2 focus:ring-inset focus:ring-brand-secondary sm:text-sm sm:leading-6"
          placeholder={placeholder}
        />
        <SubmitButton
          variant="secondary"
          className="flex-none"
          onClick={() => {
            track("Newsletter submit");
          }}
        >
          {label}
        </SubmitButton>
      </div>

      <div className="mx-auto max-w-md flex flex-col gap-4 justify-center text-center">
        {link && (
          <Link
            href={link}
            className="mt-3 text-center text-sm text-gray-600 dark:text-gray-400"
          >
            Datenschutz
          </Link>
        )}

        <p aria-live="polite" className="sr-only">
          {state?.error}
        </p>
      </div>
    </form>
  );
};

export { EmailForm };
