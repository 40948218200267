import { storyblokEditable } from "@storyblok/react/rsc";

import { Headline } from "@/components/typography/Headline";
import { Tagline } from "@/components/typography/Tagline";
import { InfiniteSlider } from "@/components/ui/InfiniteSlider";
import type { HeroStoryblok } from "@/component-types-sb";

type HeroProps = {
  blok: HeroStoryblok;
};

const Hero = ({ blok }: HeroProps) => (
  <section
    {...storyblokEditable(blok)}
    className="w-full flex items-center justify-center bg-gray-800 dark:bg-black"
  >
    <div className="relative max-w-7xl mx-auto h-auto px-6 lg:px-8 overflow-hidden">
      <div className="py-6 md:py-12 absolute max-w-md h-full flex flex-col items-start justify-center text-black bg-gradient-to-r from-gray-800 via-gray-800/90 via-60% z-30 dark:from-black dark:via-black/90">
        <Tagline text={blok.tagline} className="text-white" />

        <Headline
          as="h1"
          title={blok.headline}
          className="text-white gradient-underline"
        />
        <p className="mt-3 text-lg leading-8 text-gray-400 mb-6">{blok.text}</p>
      </div>

      <div className="py-6 md:py-12 flex flex-col antialiased items-center justify-center relative overflow-hidden">
        <InfiniteSlider
          items={blok.items as any}
          direction="left"
          speed="normal"
        />
      </div>
    </div>
  </section>
);

export default Hero;
