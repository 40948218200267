import { storyblokEditable } from "@storyblok/react/rsc";
import type { ProductStoryblok } from "@/component-types-sb";

import { Headline } from "@/components/typography/Headline";

type ProductProps = {
  blok: ProductStoryblok;
};

const Product = ({ blok }: ProductProps) => {
  if (!blok) {
    return null;
  }

  return (
    <article
      {...storyblokEditable(blok)}
      className="pt-12 max-w-7xl mx-auto text-center"
    >
      <Headline as="h1" title={blok.name} className="mb-8" />
    </article>
  );
};

export default Product;
