import Link from "next/link";
import { Suspense, useState } from "react";
import { track } from "@vercel/analytics";
import { storyblokEditable } from "@storyblok/react/rsc";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

import { Button } from "@/components/ui/Button";
import { Modal } from "@/components/layout/Modal";
import { Spinner } from "@/components/ui/Spinner";
import type { CtaStoryblok } from "@/component-types-sb";

type CtaProps = {
  blok: CtaStoryblok;
};

const Cta = ({ blok }: CtaProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const href =
    blok?.link.linktype === "email"
      ? `mailto:${blok?.link.email}`
      : blok?.link.linktype === "url"
      ? blok?.link.cached_url
      : blok?.link.anchor
      ? `/${[blok?.link.cached_url, blok.link.anchor].join("#")}`
      : blok?.link.linktype === "story"
      ? `/${blok?.link.cached_url}`
      : "#";

  return (
    <>
      <Modal
        className="max-w-[1300px]"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Suspense fallback={<Spinner />}>
          <iframe
            src={href}
            allowFullScreen
            className="w-full min-h-[700px]"
          ></iframe>
        </Suspense>
      </Modal>

      <Link
        href={href || "#"}
        target={blok.link?.target || "_self"}
        onClick={(e) => {
          if (blok.modal) {
            e.preventDefault();
            setModalOpen(true);
          }
        }}
        aria-label="Mehr Informationen"
        {...storyblokEditable(blok)}
      >
        {blok.type === "button" ? (
          <Button
            label={blok.label}
            variant={blok.variant || "primary"}
            size="large"
            icon={blok.arrow && <ArrowRightIcon className="w-4 h-4" />}
            onClick={() => {
              blok.tracking && track(blok.tracking);
            }}
          />
        ) : (
          <div className="inline-flex gap-2 items-center underline">
            <ArrowRightIcon className="w-4 h-4" />
            {blok.label}
          </div>
        )}
      </Link>
    </>
  );
};

export default Cta;
