import { useSearchParams } from "next/navigation";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Tag } from "@/components/ui/Tag";
import type { LibraryStoryblok } from "@/component-types-sb";

type LibraryProps = {
  blok: LibraryStoryblok;
};

const Library = ({ blok }: LibraryProps) => {
  const searchParams = useSearchParams();
  const query = searchParams.get("category") || undefined;

  return (
    <header {...storyblokEditable(blok)}>
      {blok.header?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}

      <div className="pt-12 max-w-7xl mx-auto px-6 lg:px-8">
        <div className="flex items-start justify-center">
          {blok.categories?.map((category: any) => (
            <Tag
              key={category.id}
              name={category.name}
              icon={category.content?.icon}
              href={`?category=${category.uuid}`}
              active={query === category.uuid}
            />
          ))}
        </div>
      </div>

      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </header>
  );
};

export default Library;
