"use client";

import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import type { CompanyStoryblok } from "@/component-types-sb";

type CompanyProps = {
  blok: CompanyStoryblok;
};

const Company = ({ blok }: CompanyProps) => {
  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </article>
  );
};

export default Company;
