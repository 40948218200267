// see https://www.cookiebot.com/en/developer/
import { useEffect, useState } from "react";

const useConsent = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, forceRender] = useState();

  // The cookiebot script is downloaded and executed before other Next.js scripts
  // hence Cookiebot should be available on first render
  const consent =
    typeof window !== "undefined" ? window.Cookiebot?.consent : null;

  useEffect(() => {
    function update() {
      // @ts-ignore
      forceRender({});
    }
    window.addEventListener("CookiebotOnAccept", update);
    window.addEventListener("CookiebotOnDecline", update);
    return () => {
      window.removeEventListener("CookiebotOnAccept", update);
      window.removeEventListener("CookiebotOnDecline", update);
    };
  }, []);

  return {
    necessary: true,
    preferences: consent?.preferences || false,
    statistics: consent?.statistics || false,
    marketing: consent?.marketing || false,
  };
};

export { useConsent };
