import { renderRichText } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { clsx } from "clsx";
import { addWeeks, startOfWeek } from "date-fns";
import Image from "next/image";
import Link from "next/link";

import type { AmaSectionStoryblok } from "@/component-types-sb";
import { RichText } from "@/components/typography/RichText";
import { Divider } from "@/components/ui/Divider";
import { formatDateTime } from "@/lib/utils/date";

import { subscribe } from "@/app/email/ama/actions";
import { EmailForm } from "@/components/form/EmailForm";

const nextRepeatingDate = (repeatingDate: Date, currentDate: Date): Date => {
  const currentWeekStart = startOfWeek(currentDate);

  // Calculate the number of weeks between the recurring date and the current week start
  const weeksDifference = Math.ceil(
    (currentWeekStart.getTime() - repeatingDate.getTime()) /
      (1000 * 60 * 60 * 24 * 7)
  );

  // Calculate the next occurrence by adding weeks
  const nextDate = addWeeks(repeatingDate, weeksDifference);

  // If the next occurrence is before the current date, add one more week
  return nextDate.getTime() < currentDate.getTime()
    ? addWeeks(nextDate, 1)
    : nextDate;
};

type AmaSectionProps = { blok: AmaSectionStoryblok };

const AmaSection = ({ blok }: AmaSectionProps) => {
  const nextDate = nextRepeatingDate(new Date(blok.date), new Date());
  const nextSchedules =
    blok.repeat === "weekly"
      ? [...new Array(2)].map((_, i) => addWeeks(nextDate, i))
      : [];

  const subscribeWithDate = subscribe.bind(null, nextDate);

  return (
    <section
      {...storyblokEditable(blok)}
      className={clsx({
        ["bg-gray-100 dark:bg-gray-800"]: blok.background === "gray",
      })}
    >
      <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <div className="my-8 md:my-0">
          <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-800 dark:text-gray-100">
            {blok.headline}
          </h2>
          <p className="mb-6 md:text-lg text-gray-800 dark:text-gray-100">
            {blok.subheadline}
          </p>

          {nextSchedules.length > 0 && (
            <>
              <Divider label="Nächste Termine" />

              <ul className="grid grid-cols-2 gap-2 mb-6">
                {nextSchedules.map((date, index) => (
                  <li
                    key={index}
                    className={clsx("tracking-wide", {
                      ["font-semibold"]: index === 0,
                      ["text-gray-800 dark:text-gray-100"]: index > 0,
                    })}
                  >
                    {formatDateTime(date)}
                  </li>
                ))}
              </ul>
            </>
          )}

          <RichText html={renderRichText(blok.text)} />

          <EmailForm action={subscribeWithDate} label="Hier eintragen" />
        </div>

        {blok.image && (
          <Link
            href={blok.link?.cached_url || "#"}
            aria-label="Mehr Informationen"
          >
            <Image
              src={blok.image?.filename}
              width="912"
              height="513"
              alt={blok.image.alt || ""}
              className="w-full"
            />
          </Link>
        )}
      </div>
    </section>
  );
};

export default AmaSection;
