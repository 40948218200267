import { storyblokEditable } from "@storyblok/react/rsc";
import type { NewsletterSectionStoryblok } from "@/component-types-sb";

import { EmailForm } from "@/components/form/EmailForm";
import { subscribe } from "@/app/email/newsletter/actions";

type NewsletterSectionProps = {
  blok: NewsletterSectionStoryblok;
};

const NewsletterSection = ({ blok }: NewsletterSectionProps) => (
  <section
    className="bg-gray-200 dark:bg-gray-800"
    {...storyblokEditable(blok)}
  >
    <div className="mx-auto max-w-7xl px-6 lg:px-8 md:py-12 py-6">
      <h2 className="mx-auto max-w-2xl mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-100">
        {blok.headline}
      </h2>
      <p className="mx-auto mt-6 max-w-xl text-center text-lg leading-8 text-gray-600 dark:text-gray-400">
        {blok.text}
      </p>

      <EmailForm
        action={subscribe}
        label={blok.button}
        placeholder={blok.placeholder}
        link={blok.link?.cached_url}
        className="mx-auto"
      />
    </div>
  </section>
);

export default NewsletterSection;
