import Image from "next/image";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { cn } from "@/lib/cn";

import { Headline } from "@/components/typography/Headline";
import type { ImageTeaserStoryblok, CtaStoryblok } from "@/component-types-sb";

type ImageTeaserProps = {
  blok: ImageTeaserStoryblok;
};

const ImageTeaser = ({ blok }: ImageTeaserProps) => (
  <div
    {...storyblokEditable(blok)}
    className={cn(
      "flex flex-col items-center justify-center text-white w-full py-12 overflow-auto",
      {
        ["bg-fixed bg-center bg-cover backdrop-opacity-10 backdrop-invert"]:
          blok.background === "image",
        ["bg-gray-800"]: blok.background == "gray",
      }
    )}
    style={{
      backgroundImage:
        blok.image?.filename && blok.background == "image"
          ? `linear-gradient(rgba(22, 30, 42, 0.8), rgba(22, 30, 42, 0.8)), url(${blok.image.filename})`
          : "none",
    }}
  >
    <div
      className={cn("mx-auto max-w-7xl px-4 md:px-6 items-center", {
        ["grid md:grid-cols-2 gap-8"]: blok.image?.filename,
        ["max-w-5xl px-6 md:px-14 flex flex-col md:grid-cols-1"]:
          blok.background === "image",
      })}
    >
      <div
        className={cn({
          ["text-center "]:
            blok.background === "image" || !blok.image?.filename,
        })}
      >
        <Headline as="h2" title={blok.headline} className="text-xl mb-2" />

        {blok.text && <p className="mb-4 text-base">{blok.text}</p>}

        <div
          className={cn("flex flex-col mt-4", {
            ["items-center "]: blok.background === "image",
          })}
        >
          {blok.cta?.map((nestedBlok: CtaStoryblok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </div>

      {blok.image?.filename && blok.background !== "image" && (
        <Image
          src={blok.image.filename}
          alt={blok.image.filename || ""}
          width={200}
          height={200}
          className="order-first md:order-last w-full max-h-72 object-contain md:aspect-square rounded-md"
        />
      )}
    </div>
  </div>
);

export default ImageTeaser;
