"use client";

import { useSearchParams } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import type { BlogStoryblok } from "@/component-types-sb";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

import { Headline } from "@/components/typography/Headline";
import { Badge } from "@/components/ui/Badge";
import { Tags } from "@/components/layout/Tags";
import { relativeTimeString } from "@/lib/utils/date";

type BlogProps = {
  blok: BlogStoryblok;
};

const Blog = ({ blok }: BlogProps) => {
  const searchParams = useSearchParams();
  const query = searchParams.get("tag") || undefined;

  const articles = blok.articles?.filter((article: any) =>
    query ? article.content.tags.includes(query) : true
  );

  return (
    <section {...storyblokEditable(blok)}>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
          <Headline as="h1" title={blok.title} className="mb-4" />
          <p className="font-light text-gray-500 sm:text-xl">
            {blok.description}
          </p>
        </div>

        <Tags />

        <div className="mb-8">
          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>

        {!articles?.length && (
          <p className="text-center text-sm text-gray-500">
            Keine Artikel gefunden
          </p>
        )}

        <div className="grid gap-8 lg:grid-cols-2">
          {articles?.map((article: any) => (
            <article
              key={article.uuid}
              className="p-6 bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-black dark:border-gray-800"
            >
              <div className="flex justify-between items-center mb-5 text-gray-500">
                <Badge text={article.content?.topic} />
                <span className="text-sm">
                  {relativeTimeString(new Date(article.created_at))}
                </span>
              </div>

              <div className="grid md:grid-cols-3 gap-8">
                {article.content.image && (
                  <Link href={`/${article.full_slug}`} className="col-span-1">
                    <Image
                      src={article.content.image.filename}
                      alt={article.content.image.alt || ""}
                      width={200}
                      height={200}
                      className="w-full h-auto object-cover md:aspect-square rounded-md"
                    />
                  </Link>
                )}
                <div className="col-span-1 md:col-span-2">
                  <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                    <Link href={`/${article.full_slug}`}>{article.name}</Link>
                  </h2>
                  <p className="mb-5 font-light text-gray-500">
                    {article.content?.except}
                  </p>
                  <Link
                    href={`/${article.full_slug}`}
                    className="inline-flex items-center font-medium hover:underline"
                  >
                    Mehr lesen
                    <ArrowRightIcon className="ml-2 w-4 h-4" />
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
