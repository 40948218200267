import type { ComponentProps } from "react";
import { Video } from "lucide-react";
import { cn } from "@/lib/cn";

type SkeletonProps = {
  number?: number;
} & ComponentProps<"div">;

const Skeleton = ({ number = 1, ...rest }: SkeletonProps) => (
  <div role="status" className={cn("min-w-24", rest.className)}>
    {Array(number)
      .fill(0)
      .map((_, index) => (
        <div key={index} className="flex flex-row items-center gap-4 mb-4">
          <span className="w-10/12 bg-gray-300 h-2 rounded-full animate-pulse dark:bg-gray-700"></span>
          <span className="w-2/12 bg-gray-300 h-2 rounded-full animate-pulse dark:bg-gray-700"></span>
        </div>
      ))}
    <span className="sr-only">Loading...</span>
  </div>
);

const VideoSkeleton = ({ ...rest }: SkeletonProps) => (
  <div
    role="status"
    className={cn(
      "w-auto h-56 aspect-video flex items-center justify-center bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700",
      rest.className
    )}
  >
    <Video className="w-10 h-10 text-gray-200 dark:text-gray-600" />
    <span className="sr-only">Loading...</span>
  </div>
);

const ChartSkeleton = ({ ...rest }: SkeletonProps) => (
  <div
    role="status"
    className={cn(
      "max-w-full p-4 border border-gray-200 rounded-md shadow animate-pulse md:p-6 dark:border-gray-700",
      rest.className
    )}
  >
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
    <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div className="flex items-baseline mt-4">
      <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
      <div className="w-full h-56 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div className="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
      <div className="w-full h-64 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div className="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
      <div className="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-700"></div>
      <div className="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-700"></div>
    </div>
    <span className="sr-only">Loading...</span>
  </div>
);

export { Skeleton, VideoSkeleton, ChartSkeleton };
