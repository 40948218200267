import type { ComponentProps } from "react";
import { clsx } from "clsx";

type DividerProps = {
  label?: string;
} & ComponentProps<"hr">;

const Divider = ({ label, className }: DividerProps) => (
  <div
    className={clsx(
      "relative inline-flex items-center justify-center w-full",
      className
    )}
  >
    <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-800" />
    <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-gray-100 dark:bg-black">
      {label}
    </span>
  </div>
);

export { Divider };
