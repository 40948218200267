import Link from "next/link";
import { useState, useTransition } from "react";
import type { FormEvent } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { EyeIcon, EyeOffIcon } from "lucide-react";

import { storyblokEditable } from "@storyblok/react/rsc";
import type {
  EvaluationStoryblok,
  CourseStoryblok,
} from "@/component-types-sb";
import type { LessonEvaluation } from "@/lib/schema/course";
import { cn } from "@/lib/cn";

import { Headline } from "@/components/typography/Headline";
import { Tagline } from "@/components/typography/Tagline";
import { BodyText } from "@/components/typography/BodyText";
import { Badge } from "@/components/ui/Badge";
import { Checkbox } from "@/components/form/Checkbox";
import { SubmitButton } from "@/components/form/SubmitButton";
import { Alert } from "@/components/ui/Alert";
import { insertEvaluation } from "@/app/courses/[courseSlug]/evaluations/actions";
import { formatOrdinals } from "@/lib/utils/format";

type EvaluationProps = {
  blok: EvaluationStoryblok;
  uuid: string;
  course?: CourseStoryblok;
  completed?: LessonEvaluation;
};

const Evaluation = ({ blok, uuid, course, completed }: EvaluationProps) => {
  const [isPending, startTransition] = useTransition();
  const [errorQuestions, setErrorQuestions] = useState<string[]>([]);
  const [showHints, setShowHints] = useState(false);

  const isCompleted = Boolean(completed);

  const correctAnswersForQuestion = (value: string) => {
    let answers: string[] = [];
    blok.questions.forEach((question) => {
      if (question._uid === value) {
        answers =
          question.answers
            ?.filter((answer) => answer.correct)
            .map((answer) => answer._uid) || [];
      }
    });
    return answers;
  };

  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    // Combine multiple values (from checkboxes) into object
    const data: Record<string, string[]> = Array.from(
      formData.entries()
    ).reduce((acc: any, [key, value]) => {
      acc[key] = acc[key] || [];
      acc[key].push(value);
      return acc;
    }, {});

    // Compare with predefined answers
    const compareArrays = (a: string[], b: string[]) =>
      a.length === b.length &&
      a.every((elm: any, index: number) => elm === b[index]);

    let result: string[] = [];
    Object.entries(data).forEach(([question, answers]) => {
      const correctAnswers = correctAnswersForQuestion(question);
      const eql = compareArrays(correctAnswers, answers);
      if (!eql) {
        result.push(question);
      }
    });

    if (result.length) {
      setErrorQuestions(result);
      return;
    }

    // Insert into database
    startTransition(() => {
      insertEvaluation(uuid);
    });
  };

  return (
    <article
      className="pt-12 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8"
      {...storyblokEditable(blok)}
    >
      <ol
        className="flex items-center whitespace-nowrap mb-8"
        aria-label="Breadcrumb"
      >
        <li className="inline-flex items-center">
          <Link
            className="flex items-center text-sm text-gray-500 hover:text-brand-secondary focus:outline-none focus:text-brand-secondary"
            href={`/${course?.full_slug}`}
          >
            {course?.name}
          </Link>

          <ChevronRightIcon className="w-4 h-4 mx-1 text-gray-400" />
        </li>
        <li
          className="inline-flex items-center text-sm font-semibold text-gray-800 truncate"
          aria-current="page"
        >
          {blok.title}
        </li>
      </ol>

      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-end">
          <Tagline text="Bewertung" />

          <button
            className="text-sm inline-flex items-center gap-2 text-gray-500"
            onClick={() => setShowHints(!showHints)}
          >
            {showHints ? (
              <>
                <EyeOffIcon size="16" />
                Hinweise ausblenden
              </>
            ) : (
              <>
                <EyeIcon size="16" />
                Hinweise einblenden
              </>
            )}
          </button>
        </div>

        <Headline as="h1" title={blok.title} />

        <BodyText className="max-w-3xl mb-8">{blok.description}</BodyText>

        {isCompleted && (
          <Alert text="Du hast diese Bewertung erfolgreich abgeschlossen" />
        )}

        <form className="flex flex-col gap-y-16" onSubmit={submitForm}>
          {blok.questions?.map((question) => (
            <fieldset
              key={question._uid}
              className={cn("w-full", {
                ["bg-red-50 p-4 rounded-md"]: errorQuestions.includes(
                  question._uid
                ),
              })}
            >
              <h3
                className={cn("text-2xl font-semibold leading-8 mb-4", {
                  ["text-red-700"]: errorQuestions.includes(question._uid),
                })}
              >
                {question.text}
              </h3>

              <Badge
                className="inline"
                text={formatOrdinals(
                  question.answers?.filter((answer) => answer.correct).length ||
                    0
                )}
              />

              <ul className="flex flex-col divide-y divide-gray-200 mt-6">
                {question.answers?.map((answer) => (
                  <li
                    key={answer._uid}
                    className={cn("inline-flex gap-x-2 py-3 px-2", {
                      ["line-through"]: showHints && !answer.correct,
                      ["bg-green-50"]: showHints && answer.correct,
                    })}
                  >
                    <Checkbox
                      id={answer._uid}
                      name={question._uid}
                      label={answer.name}
                      value={answer._uid}
                      defaultChecked={isCompleted ? answer.correct : undefined}
                      disabled={isCompleted}
                    />
                  </li>
                ))}
              </ul>
            </fieldset>
          ))}

          {!isCompleted && (
            <SubmitButton className="w-full" disabled={isPending}>
              Absenden
            </SubmitButton>
          )}
        </form>
      </div>
    </article>
  );
};

export default Evaluation;
