"use client";

import { useRef } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { cn } from "@/lib/cn";

import { SubmitButton } from "@/components/form/SubmitButton";

type Size = "small" | "medium";

type SearchInputProps = {
  name: string;
  value?: string;
  label?: string;
  placeholder?: string;
  size?: Size;
};

const SearchInput = ({
  name,
  value,
  label = "Suche",
  placeholder = "Suchbegriff...",
  size = "medium",
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="group relative flex-1 flex-shrink-0">
      <div
        className={cn(
          "absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none z-10",
          {
            ["ps-0 group-focus-within:ps-3"]: size === "small",
          }
        )}
      >
        <MagnifyingGlassIcon
          className="w-5 h-6 text-gray-500"
          aria-hidden="true"
        />
      </div>
      <input
        type="search"
        id="search"
        ref={inputRef}
        name={name}
        defaultValue={value}
        className={cn(
          "p-4 w-full ps-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 transition-all duration-500 transform focus:ring-brand-secondary focus:border-brand-secondary dark:text-gray-100 dark:border-gray-700 dark:bg-gray-900",
          {
            ["p-0 w-6 text-transparent focus:w-64 focus:flex-1 focus:p-1 focus:ps-10 focus:text-inherit border-none placeholder:text-transparent cursor-pointer focus:cursor-text transition-all duration-500 transform [&::-webkit-search-cancel-button]:hidden"]:
              size === "small",
          }
        )}
        placeholder={placeholder}
        autoFocus={size === "medium"}
        autoCorrect="false"
        required
      />
      <SubmitButton
        size={size}
        variant="secondary"
        className={cn(
          "absolute end-2 bottom-2 transition-all duration-100 ease-in-out",
          {
            ["scale-x-0 group-focus-within:scale-x-100 end-0 bottom-0 rounded-l-none"]:
              size === "small",
          }
        )}
      >
        {label}
      </SubmitButton>
    </div>
  );
};

export { SearchInput };
