import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import type {
  TableOfContentsStoryblok,
  TableOfContentsItemStoryblok,
  CtaStoryblok,
} from "@/component-types-sb";
import { Headline } from "@/components/typography/Headline";
import { Accordion } from "@/components/ui/Accordion";

type ListPops = {
  blok: TableOfContentsStoryblok;
};

const TableOfContents = ({ blok }: ListPops) => (
  <nav
    {...storyblokEditable(blok)}
    className="p-4 backdrop-blur-sm border border-gray-100/50 rounded-lg bg-gray-50/50 dark:bg-gray-800 dark:border-gray-700"
  >
    <Accordion title={blok.headline || ""} className="md:hidden">
      <ol className="p-4 mt-4 space-y-4">
        {blok.items?.map((nestedBlok: TableOfContentsItemStoryblok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </ol>
    </Accordion>

    <div className="hidden md:block">
      <div className="text-center">
        {blok.headline && <Headline as="h3" title={blok.headline} />}
      </div>

      <ol className="p-4 mt-4 space-y-4">
        {blok.items?.map((nestedBlok: TableOfContentsItemStoryblok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </ol>

      <div className="flex flex-col items-center">
        {blok.cta?.map((nestedBlok: CtaStoryblok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </nav>
);

export default TableOfContents;
