import { storyblokEditable } from "@storyblok/react/rsc";

import type { ListItemStoryblok } from "@/component-types-sb";
import { Icon, type IconName } from "@/components/typography/Icon";

type ListItemProps = {
  blok: ListItemStoryblok;
};

const ListItem = ({ blok }: ListItemProps) => (
  <div className="relative pl-9" {...storyblokEditable(blok)}>
    <dt className="inline font-semibold text-gray-900 dark:text-gray-100">
      {blok.icon && (
        <Icon
          name={blok.icon as IconName}
          className="absolute left-1 top-1 text-brand-secondary dark:text-brand-primary"
        />
      )}

      {blok.name}
    </dt>{" "}
    <dd className="inline">{blok.text}</dd>
  </div>
);

export default ListItem;
