"use client";

import { createContext } from "react";
import { useContext } from "react";
import type { FeatureFlags } from "@/lib/feature-flags";

const FeaturesContext = createContext<FeatureFlags>({} as FeatureFlags);
const useFeature = () => useContext(FeaturesContext);

export { FeaturesContext, useFeature };
