import { storyblokEditable } from "@storyblok/react/rsc";
import type { TableOfContentsItemStoryblok } from "@/component-types-sb";

type TableOfContentsItemProps = {
  blok: TableOfContentsItemStoryblok;
};

const TableOfContentsItem = ({ blok }: TableOfContentsItemProps) => (
  <li
    className="pb-5 border-b border-gray-200/80 dark:border-gray-700"
    {...storyblokEditable(blok)}
  >
    <div className="text-base font-semibold text-gray-900 dark:text-white hover-underline-animation">
      {blok.link ? <a href={`#${blok.link.anchor}`}>{blok.name}</a> : blok.name}
    </div>
    {/* <p className="mt-1 text-base font-normal text-gray-500 dark:text-gray-400"></p> */}
  </li>
);

export default TableOfContentsItem;
