import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import type { FaqSectionStoryblok } from "@/component-types-sb";

type FaqSectionProps = {
  blok: FaqSectionStoryblok;
};

const FaqSection = ({ blok }: FaqSectionProps) => {
  return (
    <section className="bg-brand-primary" {...storyblokEditable(blok)}>
      <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8 lg:py-20">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">
            {blok.headline}
          </h2>

          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {blok.items?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
