import { storyblokEditable } from "@storyblok/react/rsc";
import { BodyText } from "@/components/typography/BodyText";

import type { HeadingStoryblok } from "@/component-types-sb";
import { Headline } from "@/components/typography/Headline";

type HeadingProps = {
  blok: HeadingStoryblok;
};

const Heading = ({ blok }: HeadingProps) => (
  <section {...storyblokEditable(blok)} className="p-8 mx-auto max-w-7xl">
    <Headline as="h1" title={blok.headline} className="text-center mb-4" />
    <BodyText className="max-w-3xl">{blok.text}</BodyText>
  </section>
);

export default Heading;
