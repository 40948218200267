import Link from "next/link";
import Image from "next/image";
import { useEffect, useState, useCallback } from "react";
import dynamic from "next/dynamic";
import type MuxPlayerElement from "@mux/mux-player";
import { clsx } from "clsx";
import { useAuth } from "@clerk/nextjs";
import { usePostHog } from "posthog-js/react";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { ShieldQuestion } from "lucide-react";

import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import type { LessonStoryblok, CourseStoryblok } from "@/component-types-sb";

import { Headline } from "@/components/typography/Headline";
import { BodyText } from "@/components/typography/BodyText";
import { Tagline } from "@/components/typography/Tagline";
import { RichText } from "@/components/typography/RichText";
import { SocialShare } from "@/components/typography/SocialShare";
import { Transcript } from "@/components/media/Transcript";
import type { CuePoint } from "@/components/media/TranscriptCuePoint";
import { Divider } from "@/components/ui/Divider";
import { VideoSkeleton } from "@/components/ui/Skeleton";
import { Rating } from "@/components/ui/Rating";
import { Chapters } from "@/components/ui/Chapters";
import { Button } from "@/components/ui/Button";
import { Alert } from "@/components/ui/Alert";

import {
  getLessonMetrics,
  getLessonRatings,
  markLessonAsCompleted,
  updateLessonTime,
  updateLessonRating,
} from "@/app/courses/actions";
import type { LessonMetric } from "@/lib/schema/course";
import { useConsent } from "@/lib/hooks/useConsent";

const MuxPlayer = dynamic(() => import("@mux/mux-player-react"), {
  ssr: false,
  loading: () => <VideoSkeleton className="w-full h-full" />,
});

type LessonProps = {
  blok: LessonStoryblok;
  uuid: string;
  playbackToken?: string;
  thumbnailToken?: string;
  course?: CourseStoryblok;
  completedLessons?: string[];
  showVideo?: boolean;
};

const Lesson = ({
  blok,
  uuid,
  playbackToken,
  thumbnailToken,
  course,
  completedLessons = [],
  showVideo = false,
}: LessonProps) => {
  const { userId } = useAuth();
  const [currentTime, setCurrentTime] = useState(0);
  const [playerTime, setPlayerTime] = useState(0);
  const [metric, setMetric] = useState<LessonMetric | null>();
  const [rating, setRating] = useState<number | null>(0);
  const [activeCuePoint, setActiveCuePoint] = useState<CuePoint | undefined>();

  const [showAd, setShowAd] = useState(false);
  const [adShown, setAdShown] = useState(false);
  const [seconds, setSeconds] = useState(0);

  // const adShownRef = useRef(false);
  const { statistics } = useConsent();
  const posthog = usePostHog();

  const startTime = Number(blok.ad_start_time) || 30;
  const duration = Number(blok.ad_duration) || 10;
  const ad = (blok.ad as any)?.content;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLessonMetrics(uuid);
        setMetric(data);
      } catch (err) {
        console.error(err);
      }
      // setCurrentTime(data?.time || 0);
    };
    fetchData();
  }, [uuid, setMetric]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLessonRatings(uuid);
        setRating(data?.rating || 0);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [uuid, setRating]);

  // Countdown ad view to zero
  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [seconds, setSeconds]);

  useEffect(() => {
    if (showAd) {
      setSeconds(duration);
    } else {
      setSeconds(0);
    }
  }, [showAd, setSeconds, duration]);

  const cuePoints: CuePoint[] =
    blok.cue_points?.map(({ name, time }) => ({
      time: Number(time),
      value: name,
    })) || [];

  const addCuePointsToPlayer = (playerEl: MuxPlayerElement) => {
    playerEl.addCuePoints(cuePoints);
  };

  const saveWatchProgress = async (playerEl: MuxPlayerElement) => {
    // saving time in full seconds
    const seconds = Math.floor(playerEl.currentTime);
    await updateLessonTime(uuid, seconds);
  };

  const onTimeUpdate = useCallback(
    (e: any) => {
      const { currentTime, paused } = e.target;
      const currentSeconds = Math.floor(currentTime);
      const endTime = startTime + duration;

      if (
        ad &&
        !paused &&
        !showAd &&
        !adShown &&
        currentSeconds >= startTime &&
        currentSeconds <= endTime
      ) {
        setShowAd(true);

        // Hide add after some time
        setTimeout(() => {
          setShowAd(false);
          setAdShown(true);
        }, duration * 1000);
      }
    },
    [ad, duration, startTime, showAd, setShowAd, adShown, setAdShown]
  );

  const onEnded = async () => {
    if (userId && !completedLessons?.includes(uuid)) {
      await markLessonAsCompleted(uuid);
    }
    posthog.capture("lesson completed", {
      id: blok._uid,
      playbackId: blok.playback_id,
      title: blok.title,
    });
  };

  const saveRating = async (_event: any, value: number) => {
    await updateLessonRating(uuid, value);
    const data = await getLessonRatings(uuid);
    setRating(data?.rating || 0);
    posthog.capture("lesson rating", {
      id: blok._uid,
      playbackId: blok.playback_id,
      title: blok.title,
      value: value,
    });
  };

  return (
    <article
      className="pt-12 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8"
      {...storyblokEditable(blok)}
    >
      <ol
        className="flex items-center whitespace-nowrap mb-8"
        aria-label="Breadcrumb"
      >
        <li className="inline-flex items-center">
          <Link
            className="flex items-center text-sm text-gray-500 hover:text-brand-secondary focus:outline-none focus:text-brand-secondary dark:hover:text-brand-primary dark:focus:text-brand-primary"
            href={`/${course?.full_slug}`}
          >
            {course?.name}
          </Link>

          <ChevronRightIcon className="w-4 h-4 mx-1 text-gray-400 dark:text-gray-600" />
        </li>
        <li
          className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-gray-200"
          aria-current="page"
        >
          {blok.title}
        </li>
      </ol>

      <div className="flex md:flex-row flex-col gap-4 md:divide-x-2 divide-gray-100 dark:divide-gray-800">
        <div className="basis-3/4 flex flex-col gap-4">
          {userId && (
            <Transcript
              activeCuePoint={activeCuePoint}
              cuePoints={cuePoints}
              onCuePointSelected={(cuePoint) =>
                setCurrentTime(cuePoint.time ?? 0)
              }
            />
          )}

          {showVideo ? (
            <div className="relative">
              {ad && (
                <Link
                  href={ad.link?.url || `/${ad.link?.cached_url}` || "#"}
                  target={ad.link?.target || "_self"}
                  onClick={() => {
                    setShowAd(false);
                  }}
                  className={clsx(
                    "flex flex-col items-center absolute w-full h-full z-10 bg-brand-primary p-12",
                    {
                      ["hidden"]: !showAd,
                    }
                  )}
                >
                  <Image
                    src={ad.image?.filename}
                    alt={ad.image?.alt || ""}
                    sizes="(max-width: 768px) 100vw, 30vw"
                    width={1080}
                    height={608}
                    className="w-full h-full object-contain"
                  />
                  <button className="bg-black text-white text-sm">
                    In {seconds} Sekunden geht es weiter...
                  </button>
                </Link>
              )}

              <MuxPlayer
                streamType="on-demand"
                currentTime={currentTime}
                // startTime={metric?.time || 0}
                playbackId={blok.playback_id}
                tokens={{
                  playback: playbackToken,
                  thumbnail: thumbnailToken,
                }}
                metadataVideoTitle={blok.title}
                metadataViewerUserId={userId || undefined}
                preload="auto"
                paused={showAd}
                onPause={({ target }) => {
                  saveWatchProgress(target as MuxPlayerElement);
                }}
                onTimeUpdate={onTimeUpdate}
                onEnded={onEnded}
                onLoadedMetadata={({ target }) => {
                  addCuePointsToPlayer(target as MuxPlayerElement);
                }}
                onCuePointChange={({ target }) => {
                  setActiveCuePoint(
                    (target as MuxPlayerElement).activeCuePoint
                  );
                }}
                onCuePointsChange={({ target }) => {
                  setActiveCuePoint(
                    (target as MuxPlayerElement).activeCuePoint
                  );
                }}
                className="aspect-video"
                disableCookies={!statistics}
                accentColor="#fbc02d"
              />
            </div>
          ) : (
            <>
              <Alert text="Im kostenlosen Tarif kannst Du nur ein Kurs-Video pro Tag anschauen. Wir arbeiten aktuell an Premium-Tarifen." />

              <Image
                src={blok.image?.filename || "/d.svg"}
                alt={blok.image?.alt || ""}
                sizes="(max-width: 768px) 100vw, 30vw"
                width={1080}
                height={608}
                className="w-full h-auto mb-4"
              />
            </>
          )}

          <div className="flex justify-between">
            <Tagline text={blok.chapter as string} />
            <Rating
              value={rating}
              showText
              onClick={userId ? saveRating : undefined}
            />
            <SocialShare />
          </div>

          <Headline as="h1" title={blok.title} className="mb-4" />
          <BodyText className="max-w-3xl tracking-wide">
            {blok.description}
          </BodyText>

          <RichText
            html={renderRichText(blok.exercise)}
            className={clsx("max-w-3xl tracking-wide", {
              ["line-clamp-3"]: !userId,
            })}
          />

          {blok.transcript && (
            <>
              <Divider label="Transcript" />

              <section className="overflow-y-auto h-96 bg-gray-50 rounded-md p-6 dark:bg-gray-950">
                <blockquote className="italic font-light text-gray-900 dark:text-gray-100">
                  <svg
                    className="w-8 h-8 text-gray-400 mb-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 14"
                  >
                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                  </svg>
                  <p>{blok.transcript}</p>
                </blockquote>
              </section>
            </>
          )}
        </div>

        <aside className="px-4 basis-1/4 flex flex-col">
          <Chapters
            items={course?.content.lessons}
            completed={completedLessons}
            chapter={blok.chapter}
            uuid={uuid}
          />

          <div className="mt-8 flex flex-col gap-4">
            {userId && blok.evaluation && (
              <Link
                href={`/${(blok.evaluation as any).full_slug}`}
                onClick={() =>
                  posthog.capture("evaluation click", {
                    title: (blok.evaluation as any).content.title,
                  })
                }
              >
                <Button
                  variant="black"
                  label={(blok.evaluation as any).content.title}
                  icon={
                    <ShieldQuestion
                      size="24"
                      className="flex-none text-white"
                    />
                  }
                  className="w-full text-sm"
                />
              </Link>
            )}
          </div>

          <div className="mt-8">
            {blok.files?.map((nestedBlok: any) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        </aside>
      </div>
    </article>
  );
};

export default Lesson;
