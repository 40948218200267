import { storyblokEditable } from "@storyblok/react/rsc";

import { Avatar } from "@/components/ui/Avatar";
import type { TestimonialStoryblok } from "@/component-types-sb";

type TestimonialProps = {
  blok: TestimonialStoryblok;
};

const Testimonial = ({ blok }: TestimonialProps) => {
  const { text, image, headline, subheadline } = blok.content || blok;

  return (
    <div
      className="pt-8 sm:inline-block sm:w-full sm:px-4"
      {...storyblokEditable(blok)}
    >
      <figure className="bg-white rounded-lg border border-gray-200 p-8 text-sm leading-6 dark:bg-black dark:border-gray-800">
        <blockquote className="text-gray-900 dark:text-gray-100">
          <p>{`“${text}”`}</p>
        </blockquote>
        <figcaption className="mt-6 flex items-center gap-x-4">
          <Avatar src={image?.filename} size="large" rounded className="mr-4" />
          <div>
            <div className="font-semibold text-gray-900 dark:text-gray-100">
              {headline}
            </div>
            <div className="text-gray-600 italic dark:text-gray-400">
              {subheadline}
            </div>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default Testimonial;
