import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { cn } from "@/lib/cn";
import { Lightbulb, Info, TriangleAlert } from "lucide-react";

import { RichText } from "@/components/typography/RichText";
import type { InfoBoxStoryblok } from "@/component-types-sb";

type InfoBoxPops = {
  blok: InfoBoxStoryblok;
};

const InfoBox = ({ blok }: InfoBoxPops) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        "p-4 rounded-md border border-gray-300 flex flex-row gap-2 my-2",
        {
          ["bg-gray-200 border-none dark:bg-gray-800 italic font-light"]:
            blok.type == "tip",
          ["bg-brand-secondary/20 text-white border-none"]: blok.type == "info",
          ["bg-red-500/20 text-white border-none"]: blok.type == "warning",
        }
      )}
    >
      {blok.type === "tip" && <Lightbulb size={24} className="flex-shrink-0" />}
      {blok.type === "info" && (
        <Info size={24} className="text-brand-secondary flex-shrink-0" />
      )}
      {blok.type === "warning" && (
        <TriangleAlert size={24} className="text-red-500 flex-shrink-0" />
      )}
      <RichText html={renderRichText(blok.text)} />
    </div>
  );
};

export default InfoBox;
