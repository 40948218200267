"use client";

import Link from "next/link";
import { ChevronLeft, ChevronRight, ArrowRightIcon } from "lucide-react";
// import { track } from "@vercel/analytics";
import { useState, useEffect } from "react";

import { Carousel } from "@/components/ui/Carousel";
import type { Event } from "@/lib/schema/event";
import { formatDate } from "@/lib/utils/date";

const Topbar = () => {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch("/api/events");
        const json = await res.json();
        setEvents(json);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="bg-brand-primary text-black text-xs">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex flex-col md:flex-row gap-x-2 justify-between">
        <section
          role="banner"
          className="w-full md:max-w-xl lg:max-w-2xl py-1 flex items-center"
        >
          {events?.length > 0 && (
            <ChevronLeft size={24} className="opacity-50" />
          )}
          <Carousel
            id="banner"
            autoplay
            delay={8000}
            options={{
              loop: true,
            }}
            className="basis-full"
          >
            {events?.map((event) => (
              <Link
                key={event.id}
                href={`/events/${event.slug}`}
                // onClick={() => {
                //   track("Event click", { slug: event.name });
                // }}
                className="flex justify-start items-center gap-1"
              >
                <div className="flex gap-1 truncate">
                  <time className="font-semibold uppercase">
                    {formatDate(new Date(event!.date))}
                  </time>
                  {event?.name}
                  <ArrowRightIcon size={16} />
                </div>
              </Link>
            ))}
          </Carousel>
          {events?.length > 0 && (
            <ChevronRight size={24} className="opacity-50" />
          )}
        </section>

        <ul className="text-white flex gap-x-4 py-1 justify-end items-center mx-auto md:mx-0">
          <li>
            <Link
              href="/about"
              className="relative text-gray-800 hover-underline-animation px-1.5 py-1 rounded-sm font-medium"
            >
              Über Dialetics
            </Link>
          </li>
          <li>
            <Link
              href="/kontakt"
              className="relative text-gray-800 hover-underline-animation px-1.5 py-1 rounded-sm font-medium"
            >
              Kontakt
            </Link>
          </li>
          <li>
            <a
              href="https://www.instagram.com/mydialetics/"
              target="_blank"
              aria-label="Instagram"
            >
              <svg
                className="flex-shrink-0 w-4 h-4 text-gray-800/50"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { Topbar };
