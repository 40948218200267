import Link from "next/link";
import type { ComponentProps } from "react";

import { cn } from "@/lib/cn";
import { Icon } from "@/components/typography/Icon";
import type { IconName } from "@/components/typography/Icon";

type TagProps = {
  name: string;
  icon?: IconName;
  href: string;
  active?: boolean;
} & ComponentProps<typeof Link>;

const Tag = ({
  name,
  icon,
  href,
  active = false,
  onClick,
  className,
}: TagProps) => (
  <Link
    href={href}
    onClick={onClick}
    className={cn(
      "inline-flex items-center gap-2 text-brand-secondary hover:text-white border border-brand-secondary hover:bg-brand-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-1.5 text-center me-2 mb-2 dark:text-white",
      className,
      {
        ["bg-brand-secondary text-white"]: active,
      }
    )}
  >
    {icon && <Icon name={icon} />}
    {name}
  </Link>
);

export { Tag };
