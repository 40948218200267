import { storyblokEditable } from "@storyblok/react/rsc";

import { Headline } from "@/components/typography/Headline";
import { BodyText } from "@/components/typography/BodyText";
import type { TextListStoryblok } from "@/component-types-sb";

type TextListProps = {
  blok: TextListStoryblok;
};

const TextList = ({ blok }: TextListProps) => (
  <section
    {...storyblokEditable(blok)}
    className="py-8 flex flex-col items-center"
  >
    <Headline as="h3" title={blok.headline} />
    <BodyText className="text-base max-w-3xl">{blok.text}</BodyText>
  </section>
);

export default TextList;
