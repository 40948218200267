import type { ReactNode } from "react";
import { Children } from "react";

type SliderProps = {
  id?: string;
  children: ReactNode;
};

const Slider = ({ id, children }: SliderProps) => {
  const childrenArray = Children.toArray(children);
  return (
    <div className="mt-8 snap-x scroll-px-4 snap-mandatory scroll-smooth flex gap-4 overflow-x-auto pb-10">
      {childrenArray?.map((item, index) => (
        <div
          key={`${id}-${index}`}
          className="shrink-0 md:w-1/4 w-1/2 snap-start"
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export { Slider };
