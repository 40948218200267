const pr = new Intl.PluralRules("en-US", { type: "ordinal" });
const numberFormatter = new Intl.NumberFormat("de-DE");

export const formatOrdinals = (n: number) => {
  const rule = pr.select(n);

  const suffixes = new Map([
    ["one", "Antwort"],
    ["two", "Antworten"],
    ["few", "Antworten"],
    ["other", "Antwort"],
  ]);
  const suffix = suffixes.get(rule);
  return `${numberFormatter.format(n)} ${suffix}`;
};
