import { TranscriptCuePoint } from "./TranscriptCuePoint";
import type { CuePoint } from "./TranscriptCuePoint";

const Transcript = ({
  cuePoints = [],
  onCuePointSelected = () => {},
  activeCuePoint,
}: {
  cuePoints: CuePoint[];
  onCuePointSelected?: (cuePoint: CuePoint) => void;
  activeCuePoint?: CuePoint;
}) => {
  return (
    <section className="inline-flex gap-1">
      {cuePoints.map((cuePoint) => {
        const active = cuePoint.time === activeCuePoint?.time;
        return (
          <TranscriptCuePoint
            key={cuePoint.time}
            onCuePointSelected={onCuePointSelected}
            cuePoint={cuePoint}
            active={active}
          />
        );
      })}
    </section>
  );
};

export { Transcript };
