"use client";

import { useFormStatus } from "react-dom";
import type { ComponentProps } from "react";
import { cn } from "@/lib/cn";

import { Spinner } from "@/components/ui/Spinner";

type Size = "small" | "medium";
type Variant = "primary" | "secondary" | "destructive" | "black" | "neutral";

type SubmitButtonProps = {
  size?: Size;
  variant?: Variant;
} & ComponentProps<"button">;

const SubmitButton = ({
  children,
  disabled,
  size = "medium",
  variant = "primary",
  ...rest
}: SubmitButtonProps) => {
  const { pending } = useFormStatus();

  return (
    <button
      type="submit"
      className={cn(
        "inline-flex items-center justify-center gap-2 focus:ring-4 focus:outline-none font-medium rounded-md text-center",
        {
          ["px-5 py-2.5 text-sm"]: size === "medium",
          ["px-3 py-1.5 text-xs"]: size === "small",
          ["text-black bg-brand-primary hover:bg-yellow-300 focus:ring-yellow-300"]:
            variant === "primary",
          ["text-white bg-brand-secondary hover:bg-blue-700 focus:ring-blue-400"]:
            variant === "secondary",
          ["text-white bg-red-600 hover:bg-red-500 focus:ring-red-300"]:
            variant === "destructive",
          ["text-white bg-black hover:bg-gray-700 focus:ring-gray-700"]:
            variant === "black",
          ["text-black bg-gray-200 hover:bg-gray-300 focus:ring-gray-300"]:
            variant === "neutral",
          ["opacity-25"]: disabled,
        },
        rest.className
      )}
      disabled={pending || disabled}
    >
      {children}

      {pending && <Spinner size={size} />}
    </button>
  );
};

export { SubmitButton };
