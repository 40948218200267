"use client";

import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import { clsx } from "clsx";
import { XMarkIcon } from "@heroicons/react/20/solid";

type ModalProps = {
  title?: string;
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  className?: string;
};

const Modal = ({
  open = false,
  onClose,
  title = "",
  children,
  ...rest
}: ModalProps) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        aria-modal="true"
        aria-labelledby="modal-title"
        onClose={onClose}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={clsx(
                  "w-full transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all",
                  rest.className
                )}
              >
                <div className="flex justify-end">
                  {title && (
                    <DialogTitle
                      as="h3"
                      id="modal-title"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </DialogTitle>
                  )}

                  <XMarkIcon
                    className="h-6 w-6 flex-none text-gray-900 cursor-pointer"
                    aria-hidden="true"
                    onClick={onClose}
                  />
                </div>

                <div className="flex flex-col items-center">{children}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { Modal };
