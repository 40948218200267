import type { TeamSectionStoryblok } from "@/component-types-sb";
import { storyblokEditable } from "@storyblok/react/rsc";
import { cn } from "@/lib/cn";

import Team from "./Team";

type TeamSectionProps = {
  blok: TeamSectionStoryblok;
};

const TeamSection = ({ blok }: TeamSectionProps) => (
  <section
    className="py-8 px-4 mx-auto max-w-screen-lg text-center lg:py-16 lg:px-6 "
    {...storyblokEditable(blok)}
  >
    <h2 className="mx-auto max-w-2xl mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-100">
      {blok.headline}
    </h2>
    <p className="mx-auto mb-6 max-w-xl text-center text-lg leading-8 text-gray-600 dark:text-gray-400">
      {blok.text}
    </p>

    <div
      className={cn("grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3", {
        ["sm:grid-cols-1 md:grid-cols-1"]: blok.items?.length === 1,
      })}
    >
      {blok.items?.map((nestedBlok: any) => (
        <Team blok={nestedBlok} key={nestedBlok.uuid} />
      ))}
    </div>
  </section>
);

export default TeamSection;
