import Image from "next/image";
import { renderToStaticMarkup } from "react-dom/server";
import { renderRichText } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { cn } from "@/lib/cn";

import { Headline } from "@/components/typography/Headline";
import { RichText } from "@/components/typography/RichText";
import Cta from "@/components/storyblok/Cta";
import type { HeaderImageStoryblok } from "@/component-types-sb";

type HeaderImageProps = {
  blok: HeaderImageStoryblok;
  className?: string;
};

const HeaderImage = ({ blok, ...rest }: HeaderImageProps) => (
  <section {...storyblokEditable(blok)} className="md:h-[640px] relative">
    <Image
      src={blok.image.filename}
      alt={blok.image.alt || ""}
      className={cn("w-full h-32 md:h-full object-cover", rest.className)}
      width="1484"
      height="640"
      style={{
        aspectRatio: "3/2",
        objectFit: "cover",
      }}
      priority
    />
    <div
      className={cn(
        "md:absolute inset-0 left-0 top-0 flex flex-col justify-center bg-gray-200 md:bg-transparent backdrop-brightness-60 py-4",
        {
          "justify-end": blok.align == "bottom",
        }
      )}
    >
      <div className="max-w-7xl mx-auto px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div>
          {blok.headline && (
            <Headline as="h1" className="md:text-white" title={blok.headline} />
          )}

          <p className="my-8 text-2xl font-semibold md:text-white">
            {blok.subheadline}
          </p>

          <RichText
            html={renderRichText(blok.text, {
              resolver: (component, blok) => {
                switch (component) {
                  case "cta":
                    return renderToStaticMarkup(
                      <div className="not-prose my-8">
                        <Cta blok={blok} />
                      </div>
                    );
                }
              },
            })}
            className="md:text-white"
          />
        </div>

        {blok.body?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </section>
);

export default HeaderImage;
