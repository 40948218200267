import { renderToStaticMarkup } from "react-dom/server";
import { renderRichText } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { cn } from "@/lib/cn";

import { RichText } from "@/components/typography/RichText";
import Cta from "@/components/storyblok/Cta";
import type { TextContentStoryblok, CtaStoryblok } from "@/component-types-sb";

type TextContentProps = {
  blok: TextContentStoryblok;
};

const TextContent = ({ blok }: TextContentProps) => (
  <section
    id={blok._uid}
    className={cn("mx-auto max-w-7xl px-6 lg:px-8 md:py-16 py-6 scroll-mt-16", {
      ["p-0"]: blok.size === "small",
      ["max-w-3xl"]: blok.align === "center",
    })}
    {...storyblokEditable(blok)}
  >
    {blok.size === "small" ? (
      <h3 className="break-words hyphens-auto mb-4 text-2xl tracking-tight font-semibold text-gray-800 dark:text-gray-100">
        {blok.headline}
      </h3>
    ) : (
      <h2 className="break-words hyphens-auto mb-4 text-4xl tracking-tight font-semibold text-gray-800 dark:text-gray-100">
        {blok.headline}
      </h2>
    )}
    <p className="mb-6 max-w-3xl md:text-lg text-gray-800 dark:text-gray-100">
      {blok.subheadline}
    </p>

    <div
      className={cn({
        "md:columns-2": blok.columns == "2",
        "md:columns-3": blok.columns == "3",
      })}
    >
      <RichText
        html={renderRichText(blok.text, {
          resolver: (component, blok) => {
            switch (component) {
              case "cta":
                return renderToStaticMarkup(
                  <div className="not-prose max-w-xl mx-auto my-8 text-center">
                    <Cta blok={blok} />
                  </div>
                );
            }
          },
        })}
      />
    </div>

    <div className="flex flex-col md:flex-row items-center gap-6 mb-8">
      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>

    <div className="flex flex-col items-center">
      {blok.cta?.map((nestedBlok: CtaStoryblok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </section>
);

export default TextContent;
