import type { InputHTMLAttributes } from "react";

type TextInputProps = {
  name: string;
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const TextInput = ({ label, name, type = "text", ...rest }: TextInputProps) => (
  <div className="relative z-0 w-full mb-5 group">
    <input
      type={type}
      name={name}
      id={name}
      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-brand-secondary peer dark:text-gray-100 dark:border-gray-700"
      placeholder=" "
      {...rest}
    />
    <label
      htmlFor={name}
      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-brand-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
    >
      {label}
      {rest.required && (
        <abbr title="required" className="text-red-600 pl-0.5">
          *
        </abbr>
      )}
    </label>
  </div>
);

export { TextInput };
