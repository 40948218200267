"use client";

// taken from https://ui.aceternity.com/components/infinite-moving-cards

import Link from "next/link";
import Image from "next/image";
import { useRef, useEffect, useState } from "react";
import { cn } from "@/lib/cn";
import type { AssetStoryblok } from "@/component-types-sb";
import { track } from "@vercel/analytics";

type InfiniteSliderProps = {
  items: {
    id: string;
    name: string;
    full_slug: string;
    content?: {
      image: AssetStoryblok;
    };
  }[];
  direction?: "left" | "right";
  speed?: "fast" | "normal" | "slow";
  pauseOnHover?: boolean;
  className?: string;
};

const InfiniteSlider = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
  className,
}: InfiniteSliderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    addAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [start, setStart] = useState(false);

  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }

  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "left") {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "forwards"
        );
      } else {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "reverse"
        );
      }
    }
  };

  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "fast") {
        containerRef.current.style.setProperty("--animation-duration", "20s");
      } else if (speed === "normal") {
        containerRef.current.style.setProperty("--animation-duration", "40s");
      } else {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={cn(
        "scroller relative z-20 max-w-7xl overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]",
        className
      )}
    >
      <ul
        ref={scrollerRef}
        className={cn("flex min-w-full shrink-0 gap-4 w-max flex-nowrap", {
          ["animate-scroll"]: start,
          ["hover:[animation-play-state:paused]"]: pauseOnHover,
        })}
      >
        <li className="grid grid-cols-3 grid-rows-3 gap-6 justify-center">
          {items?.map((item: any, index: number) => (
            <Link
              key={item.id}
              href={item.full_slug}
              onClick={() => {
                track("Hero click", { slug: item.slug });
              }}
              className={cn("rounded-lg shadow-md group mb-4 overflow-hidden", {
                ["col-span-2 row-span-2 h-full"]: index <= 1,
                // ["col-start-3 row-span-2 w-48 h-full"]: index == 1,
                ["row-start-3"]: index > 1,
              })}
            >
              <figure className="relative w-full h-full before:bg-black before:bg-opacity-20">
                <Image
                  src={item.content?.image?.filename}
                  alt={item.content?.image?.alt || ""}
                  width={187}
                  height={223}
                  className="w-full h-full rounded-lg aspect-video object-cover group-hover:scale-110"
                  priority
                />
                <figcaption className="absolute inline bottom-0 left-0 mb-2 p-1 text-xs tracking-wide font-semibold text-white group-hover:text-black group-hover:bg-brand-primary">
                  {item.name}
                </figcaption>
              </figure>
            </Link>
          ))}
        </li>
      </ul>
    </div>
  );
};

export { InfiniteSlider };
export type { InfiniteSliderProps };
