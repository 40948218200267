import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { Carousel } from "@/components/ui/Carousel";

import type { LogoListStoryblok } from "@/component-types-sb";

type LogoListProps = {
  blok: LogoListStoryblok;
};

const LogoList = ({ blok }: LogoListProps) => (
  <div
    {...storyblokEditable(blok)}
    className="container md:max-w-7xl md:mx-auto flex flex-col items-center gap-4 mx-auto px-6 lg:px-8 md:py-12 py-6"
  >
    <div className="text-lg font-semibold text-gray-500">{blok.headline}</div>
    <p>{blok.text}</p>

    <Carousel
      id="logo-list"
      className="basis-1/2 md:basis-1/3 lg:basis-1/5 flex items-center"
      autoscroll
      options={{
        loop: true,
        slidesToScroll: 5,
        stopOnMouseEnter: true,
        stopOnInteraction: false,
      }}
    >
      {blok.items?.map((nestedBlok: any) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </Carousel>
  </div>
);

export default LogoList;
