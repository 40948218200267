import { ReactNode } from "react";
import type { ComponentProps } from "react";
import { cn } from "@/lib/cn";

type Variant =
  | "primary"
  | "secondary"
  | "black"
  | "white"
  | "neutral"
  | "mylife-green"
  | "mylife-gray";
type Size = "small" | "medium" | "large";

type ButtonProps = {
  label: string;
  hideLabel?: boolean;
  variant?: Variant;
  size?: Size;
  icon?: ReactNode;
  children?: ReactNode;
} & ComponentProps<"button">;

const Button = ({
  label,
  hideLabel,
  variant = "primary",
  size = "medium",
  icon,
  children,
  ...rest
}: ButtonProps) => (
  <button
    type="button"
    className={cn(
      "group focus:ring-4 font-semibold rounded-md me-6 focus:outline-none",
      {
        ["px-5 py-2.5 text-base"]: size === "large",
        ["px-5 py-2.5 text-sm"]: size === "medium",
        ["px-3 py-1.5 text-xs font-medium"]: size === "small",
        ["text-black bg-brand-primary hover:bg-yellow-300 focus:ring-yellow-400"]:
          variant === "primary",
        ["text-white bg-brand-secondary hover:bg-blue-700 focus:ring-blue-400"]:
          variant === "secondary",
        ["text-white bg-black hover:bg-gray-700 focus:ring-gray-700"]:
          variant === "black",
        ["text-gray-700 bg-white border border-gray-300 dark:bg-gray-200"]:
          variant === "white",
        ["text-black bg-gray-200 hover:bg-gray-300 focus:ring-gray-300 dark:bg-gray-400"]:
          variant === "neutral",
        ["text-white bg-mylife-green"]: variant === "mylife-green",
        ["text-white bg-mylife-gray"]: variant === "mylife-gray",
        ["inline-flex gap-2 items-center justify-center"]: icon,
      },
      rest.className
    )}
    onClick={rest.onClick}
  >
    {icon}

    {hideLabel ? (
      <span className="hidden  opacity-0 transition-all duration-200 group-hover:inline group-hover:opacity-100">
        {label}
      </span>
    ) : (
      label
    )}
  </button>
);

export { Button };
