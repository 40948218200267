"use client";

import { Children, useState, useEffect, useCallback } from "react";
import type { PropsWithChildren, KeyboardEvent } from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import { cn } from "@/lib/cn";

import type { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import AutoScroll from "embla-carousel-auto-scroll";
import type { AutoScrollOptionsType } from "embla-carousel-auto-scroll";

type CarouselProps = PropsWithChildren & {
  className?: string;
  id?: string;
  autoplay?: boolean;
  autoscroll?: boolean;
  delay?: number;
  controls?: boolean;
  options?: EmblaOptionsType & AutoScrollOptionsType;
};

const Carousel = ({
  children,
  className,
  id = "carousel",
  autoplay = false,
  autoscroll = false,
  delay = 4000,
  controls = false,
  options = {},
}: CarouselProps) => {
  const childrenArray = Children.toArray(children);
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ delay, playOnInit: autoplay }),
    AutoScroll({
      startDelay: delay,
      playOnInit: autoscroll,
      ...options,
    }),
  ]);

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );

  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "ArrowLeft") {
        scrollPrev();
      } else if (event.key === "ArrowRight") {
        scrollNext();
      }
    },
    [scrollNext, scrollPrev]
  );

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div
      className="flex relative items-center overflow-hidden"
      ref={emblaRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <div className="flex w-full" id={id}>
        {childrenArray.map((item, index) => (
          <div
            key={index}
            className={cn("min-w-0 flex-grow-0 flex-shrink-0", className)}
          >
            {item}
          </div>
        ))}
      </div>

      {/* Controls */}
      {controls && (
        <>
          <button
            aria-label="Zurück"
            onClick={scrollPrev}
            className={cn(
              "hidden md:flex absolute left-0 z-1 items-center justify-center hover:bg-white hover:rounded-full",
              {
                ["hidden"]: prevBtnDisabled,
              }
            )}
          >
            <ChevronLeftIcon
              className="w-16 h-16 text-brand-primary"
              aria-hidden="true"
            />
          </button>

          <button
            aria-label="Weiter"
            onClick={scrollNext}
            className={cn(
              "hidden md:flex absolute right-0 z-1 items-center justify-center hover:bg-white hover:rounded-full",
              {
                ["hidden"]: nextBtnDisabled,
              }
            )}
          >
            <ChevronRightIcon
              className="w-16 h-16 text-brand-primary"
              aria-hidden="true"
            />
          </button>
        </>
      )}
    </div>
  );
};

export { Carousel };
