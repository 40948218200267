import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";

import { Avatar } from "@/components/ui/Avatar";
import type { TeamStoryblok } from "@/component-types-sb";

type TeamProps = {
  blok: TeamStoryblok;
};

const Team = ({ blok }: TeamProps) => {
  const { text, image, headline, subheadline } = blok.content || blok;

  return (
    <div
      className="text-center text-gray-500 dark:text-gray-400"
      {...storyblokEditable(blok)}
    >
      {image?.filename && (
        <Image
          src={image.filename}
          alt={image.alt || ""}
          width={360}
          height={360}
          className="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
        />
      )}
      <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
        {headline}
      </h3>
      <span className="text-gray-500 dark:text-gray-400">{subheadline}</span>
      <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
        {text}
      </p>
    </div>
  );
};

export default Team;
