import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import type { FaqStoryblok } from "@/component-types-sb";

type FaqProps = {
  blok: FaqStoryblok;
};

const Faq = ({ blok }: FaqProps) => {
  return (
    <section {...storyblokEditable(blok)}>
      <h3 className="text-2xl font-semibold leading-8 mb-2">{blok.title}</h3>

      <dl className="space-y-3 divide-y divide-gray-900/10 px-4 mb-8">
        {blok.items?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </dl>
    </section>
  );
};

export default Faq;
