"use client";

import { Tab } from "@headlessui/react";
import { clsx } from "clsx";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Headline } from "@/components/typography/Headline";
import type {
  FeatureGroupStoryblok,
  FeatureSectionStoryblok,
} from "@/component-types-sb";

type FeatureGroupProps = {
  blok: FeatureGroupStoryblok;
};

const FeatureGroup = ({ blok }: FeatureGroupProps) => (
  <div {...storyblokEditable(blok)} className="px-6 lg:px-8 md:py-12 py-6">
    {blok.headline && (
      <Headline as="h3" title={blok.headline} className="mb-4 text-center" />
    )}

    <Tab.Group>
      <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1 dark:bg-gray-800 max-w-4xl mx-auto">
        {blok.items?.map(
          (nestedBlok: FeatureSectionStoryblok, index: number) => (
            <Tab
              key={index}
              className={({ selected }) =>
                clsx(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                  "ring-white/60 ring-offset-2 ring-offset-brand-secondary focus:outline-none focus:ring-2 dark:ring-offset-brand-primary",
                  selected
                    ? "bg-white text-brand-secondary shadow dark:bg-black dark:text-brand-primary"
                    : "text-gray-600 hover:bg-white/[0.12] hover:text-brand-secondary dark:text-gray-400 dark:hover:text-brand-primary"
                )
              }
            >
              {nestedBlok.title || nestedBlok.headline}
            </Tab>
          )
        )}
      </Tab.List>
      <Tab.Panels>
        {blok.items?.map((nestedBlok: FeatureSectionStoryblok) => (
          <Tab.Panel
            key={nestedBlok._uid}
            className={clsx(
              "rounded-xl bg-white p-3 dark:bg-black",
              "ring-white/60 ring-offset-2 ring-offset-brand-secondary focus:outline-none focus:ring-2 dark:ring-black/60 dark:ring-offset-brand-primary"
            )}
          >
            <StoryblokComponent blok={nestedBlok} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  </div>
);

export default FeatureGroup;
