import { renderRichText } from "@storyblok/react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { clsx } from "clsx";
import Image from "next/image";
import Link from "next/link";
import { cn } from "@/lib/cn";

import type { TextImageStoryblok, CtaStoryblok } from "@/component-types-sb";
import { RichText } from "@/components/typography/RichText";
import { Button } from "@/components/ui/Button";
import { LinkWrapper } from "@/components/utils/LinkWrapper";

type TextImageProps = { blok: TextImageStoryblok };

const TextImage = ({ blok }: TextImageProps) => (
  <section
    id={blok._uid}
    className={clsx("scroll-mt-16", {
      ["bg-gray-100 dark:bg-gray-800"]: blok.background === "gray",
    })}
    {...storyblokEditable(blok)}
  >
    <div
      className={cn(
        "gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 grid grid-cols-1 md:grid-cols-2 sm:py-16 lg:px-6",
        {
          "md:grid-cols-1": blok.columns == "1",
        }
      )}
    >
      <div className="my-4 md:my-0">
        <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-800 dark:text-gray-100">
          {blok.headline}
        </h2>
        <p className="mb-6 max-w-3xl md:text-lg text-gray-800 dark:text-gray-100">
          {blok.subheadline}
        </p>
        <RichText html={renderRichText(blok.text)} />

        {blok.link?.cached_url && (
          <Link href={blok.link.cached_url}>
            <Button label={blok.button || "Mehr Infos"} className="mt-4" />
          </Link>
        )}

        <div className="flex flex-row items-center align-middle gap-1 mt-8">
          {blok.cta?.map((nestedBlok: CtaStoryblok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </div>

      {blok.image?.filename && (
        <LinkWrapper
          link={blok.link}
          className={clsx("order-first md:order-last", {
            "order-first md:order-first": blok.reverse,
          })}
          aria-label="Mehr Informationen"
        >
          <Image
            src={blok.image.filename}
            width="912"
            height="513"
            alt={blok.image.alt || ""}
            className="w-full rounded-md"
          />
        </LinkWrapper>
      )}
    </div>
  </section>
);

export default TextImage;
