import Link from "next/link";
import { ReactNode } from "react";
import type { MultilinkStoryblok } from "@/component-types-sb";

type LinkWrapperProps = {
  link?: MultilinkStoryblok;
  className?: string;
  children: ReactNode;
};

export const LinkWrapper = ({
  link,
  className,
  children,
}: LinkWrapperProps) => {
  const url = link?.url || link?.cached_url;

  return url && url !== "" ? (
    <Link href={url} className={className}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
};
