import type { PrivacyStoryblok } from "@/component-types-sb";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";

type PrivacyProps = {
  blok: PrivacyStoryblok;
};

const Privacy = ({ blok }: PrivacyProps) => {
  return (
    <article {...storyblokEditable(blok)} className="w-full">
      <div className="pt-12 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex-row gap-4">
        <div className="w-full flex md:flex-row flex-col gap-4 md:divide-x-2 divide-gray-100 dark:divide-gray-800">
          <div className="basis-3/4">
            {blok.body?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>

          <aside className="px-4 order-first md:order-last"></aside>
        </div>
      </div>
    </article>
  );
};

export default Privacy;
