import type { ComponentProps } from "react";
import { cn } from "@/lib/cn";

type BadgeProps = {
  text?: string | null;
} & ComponentProps<"p">;

const Badge = ({ text, ...rest }: BadgeProps) => {
  if (!text) {
    return null;
  }

  return (
    <p
      className={cn(
        "rounded-full bg-brand-secondary/10 px-2.5 py-1 text-xs font-semibold leading-5 text-brand-secondary dark:bg-brand-primary/20 dark:text-brand-primary",
        rest.className
      )}
    >
      {text}
    </p>
  );
};

export { Badge };
