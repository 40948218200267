"use client";

import { storyblokInit, apiPlugin } from "@storyblok/react/rsc";
import { ReactElement } from "react";

// General
import Page from "./Page";
import Contact from "./Contact";
import Signup from "./Signup";
import Privacy from "./Privacy";
import Hero from "./Hero";
import HeaderImage from "./HeaderImage";
import HeaderVideo from "./HeaderVideo";
import Heading from "./Heading";
import ImageList from "./ImageList";
import ImageTeaser from "./ImageTeaser";
import TextList from "./TextList";
import TextContent from "./TextContent";
import TextImage from "./TextImage";
import TextVideo from "./TextVideo";
import AmaSection from "./AmaSection";
import LogoList from "./LogoList";
import AssetLink from "./AssetLink";
import FileLink from "./FileLink";
import Faq from "./Faq";
import FaqItem from "./FaqItem";
import FaqSection from "./FaqSection";
import TableOfContents from "./TableOfContents";
import TableOfContentsItem from "./TableOfContentsItem";
import Cta from "./Cta";
import FeatureGroup from "./FeatureGroup";
import FeatureSection from "./FeatureSection";
import FeatureListCard from "./FeatureListCard";
import FeatureListText from "./FeatureListText";
import FeatureItem from "./FeatureItem";
import List from "./List";
import ListItem from "./ListItem";
import Grid from "./Grid";
import Accordion from "./Accordion";
import Table from "./Table";

// Course
import Library from "./Library";
import Course from "./Course";
import Lesson from "./Lesson";
import Evaluation from "./Evaluation";
import LessonSlider from "./LessonSlider";
import CourseSlider from "./CourseSlider";
import Video from "./Video";
import VideoSlider from "./VideoSlider";
import VideoCollection from "./VideoCollection";
import VideoCollectionSlider from "./VideoCollectionSlider";

// Marketing
import Blog from "./Blog";
import Article from "./Article";
import Interviews from "./Interviews";
import Interview from "./Interview";
import Slider from "./Slider";
import Pricing from "./Pricing";
import Testimonial from "./Testimonial";
import TestimonialSection from "./TestimonialSection";
import Team from "./Team";
import TeamSection from "./TeamSection";
import NewsletterSection from "./NewsletterSection";
import InfoBox from "./InfoBox";

// B2B
import Company from "./Company";
import Product from "./Product";

// Layout
import MenuItem from "./MenuItem";
import FallbackComponent from "./FallbackComponent";

storyblokInit({
  accessToken: process.env.storyblokApiToken,
  use: [apiPlugin],
  apiOptions: {
    region: "eu",
  },
  components: {
    page: Page,
    contact: Contact,
    signup: Signup,
    privacy: Privacy,
    hero: Hero,
    header_image: HeaderImage,
    header_video: HeaderVideo,
    heading: Heading,
    image_list: ImageList,
    image_teaser: ImageTeaser,
    text_list: TextList,
    text_content: TextContent,
    text_image: TextImage,
    text_video: TextVideo,
    ama_section: AmaSection,
    asset_link: AssetLink,
    file_link: FileLink,
    logo_list: LogoList,
    faq: Faq,
    faq_item: FaqItem,
    faq_section: FaqSection,
    table_of_contents: TableOfContents,
    table_of_contents_item: TableOfContentsItem,
    cta: Cta,
    feature_group: FeatureGroup,
    feature_section: FeatureSection,
    feature_list_card: FeatureListCard,
    feature_list_text: FeatureListText,
    feature_item: FeatureItem,
    list: List,
    list_item: ListItem,
    grid: Grid,
    accordion: Accordion,
    table: Table,
    // Course
    library: Library,
    course: Course,
    lesson: Lesson,
    evaluation: Evaluation,
    lesson_slider: LessonSlider,
    course_slider: CourseSlider,
    video: Video,
    video_slider: VideoSlider,
    video_collection: VideoCollection,
    video_collection_slider: VideoCollectionSlider,
    // Marketing
    blog: Blog,
    article: Article,
    interviews: Interviews,
    interview: Interview,
    slider: Slider,
    pricing: Pricing,
    testimonial: Testimonial,
    testimonial_section: TestimonialSection,
    team: Team,
    team_section: TeamSection,
    newsletter_section: NewsletterSection,
    info_box: InfoBox,
    // B2B
    company: Company,
    product: Product,
    // Layout
    menu_item: MenuItem,
  },
  enableFallbackComponent: true,
  customFallbackComponent: FallbackComponent,
});

type StoryblokProviderProps = {
  children: ReactElement;
};

export default function StoryblokProvider({
  children,
}: StoryblokProviderProps) {
  return children;
}
