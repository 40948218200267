import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import type { ListStoryblok, ListItemStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/cn";

import { Headline } from "@/components/typography/Headline";

type ListPops = {
  blok: ListStoryblok;
};

const List = ({ blok }: ListPops) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col overflow-auto mt-6"
    >
      {blok.headline && (
        <Headline as="h3" title={blok.headline} className="mb-4" />
      )}

      <dl
        className={cn(
          "max-w-xl space-y-4 text-base leading-7 text-gray-600 lg:max-w-none dark:text-gray-400",
          {
            ["mx-auto inline-flex items-start gap-6 my-5 space-y-0"]:
              blok.style === "horizontal",
          }
        )}
      >
        {blok.items?.map((nestedBlok: ListItemStoryblok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </dl>
    </div>
  );
};

export default List;
