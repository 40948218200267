import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { cn } from "@/lib/cn";

import { Headline } from "@/components/typography/Headline";
import type {
  FeatureListCardStoryblok,
  CtaStoryblok,
} from "@/component-types-sb";

type FeatureListCardProps = {
  blok: FeatureListCardStoryblok;
};

const FeatureListCard = ({ blok }: FeatureListCardProps) => (
  <section
    id={blok._uid}
    className={cn("scroll-mt-16", {
      ["bg-gray-200 dark:bg-gray-800"]: blok.background == "gray",
      ["bg-brand-primary"]: blok.background == "yellow",
    })}
    {...storyblokEditable(blok)}
  >
    <div
      className="mx-auto max-w-7xl px-6 lg:px-8 md:py-12 py-6"
      {...storyblokEditable(blok)}
    >
      {blok.headline && <Headline as="h2" title={blok.headline} />}
      <p className="mt-3 text-lg leading-8 text-gray-600 dark:text-gray-300">
        {blok.text}
      </p>

      <div
        className={cn("grid grid-cols-1 md:grid-cols-3 gap-4 my-8 text-left", {
          ["grid-cols-2 md:grid-cols-4"]: blok.items?.length === 4,
          ["md:divide-x divide-gray-300 dark:divide-gray-700"]:
            blok.style === "text",
        })}
      >
        {blok?.items?.map((nestedBlok: any) => (
          <div
            key={nestedBlok._uid}
            className="w-full flex flex-col items-start justify-between p-6 bg-white dark:bg-black border border-gray-200 dark:border-gray-800 rounded-lg shadow"
          >
            <StoryblokComponent blok={nestedBlok} style="card" />
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center">
        {blok.cta?.map((nestedBlok: CtaStoryblok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </section>
);

export default FeatureListCard;
