import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import Image from "next/image";
import Link from "next/link";
import { cn } from "@/lib/cn";

import type { FeatureSectionStoryblok } from "@/component-types-sb";
import { RichText } from "@/components/typography/RichText";
import { Tagline } from "@/components/typography/Tagline";
import type { CtaStoryblok } from "@/component-types-sb";

type FeatureSectionProps = {
  blok: FeatureSectionStoryblok;
};

const FeatureSectionProps = ({ blok }: FeatureSectionProps) => {
  return (
    <section
      className="overflow-hidden bg-white md:py-12 py-6 dark:bg-black"
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div
            className={cn("lg:pr-8 lg:pt-4", {
              "order-last": blok.reverse,
            })}
          >
            <div className="lg:max-w-lg">
              <Tagline text={blok.tagline} />

              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
                {blok.headline}
              </h2>

              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
                {blok.text}
              </p>

              {blok.richtext && (
                <RichText html={renderRichText(blok.richtext)} />
              )}

              {blok.list?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}

              {blok.cta?.map((nestedBlok: CtaStoryblok) => (
                <div className="mt-8" key={nestedBlok._uid}>
                  <StoryblokComponent blok={nestedBlok} />
                </div>
              ))}
            </div>
          </div>

          {blok.image && (
            <Link
              href={blok.link?.cached_url || "#"}
              target={blok.link?.target || "_self"}
              className={cn("order-first md:order-last", {
                "order-first md:order-first": blok.reverse,
              })}
              aria-label="Mehr Informationen"
            >
              <Image
                src={blok.image.filename}
                alt={blok.image.alt || ""}
                className={cn(
                  "w-full md:w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0",
                  {
                    "lg:-ml-48": blok.reverse,
                  }
                )}
                width={2432}
                height={1442}
              />
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default FeatureSectionProps;
