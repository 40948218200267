import Link from "next/link";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import type { SignupStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/cn";

import { Headline } from "@/components/typography/Headline";
import { SignupForm } from "@/components/form/SignupForm";
import type { Role } from "@/lib/auth/roles";

type SignupProps = {
  blok: SignupStoryblok;
};

const Signup = ({ blok }: SignupProps) => {
  return (
    <div className="flex min-h-full bg-gray-100 flex-1 flex-col items-center justify-center py-12 sm:px-6 lg:px-8 dark:bg-gray-900">
      <article
        {...storyblokEditable(blok)}
        className={cn("max-w-7xl mx-auto", {
          ["grid grid-cols-1 md:grid-cols-2 gap-6 items-center"]: blok.body,
        })}
      >
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 dark:bg-black">
            <Headline as="h1" title={blok.title} className="text-center mb-8" />

            <div className="inline-flex rounded-md shadow-sm mb-8">
              <Link
                href="user"
                aria-current="page"
                className={cn(
                  "px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white",
                  {
                    ["text-brand-secondary"]: blok.role === "user",
                  }
                )}
              >
                Betroffene
              </Link>
              <Link
                href="hcp"
                className={cn(
                  "px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white",
                  {
                    ["text-brand-secondary"]: blok.role === "hcp",
                  }
                )}
              >
                Fachpersonal
              </Link>
              <Link
                href="company"
                className={cn(
                  "px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-brand-secondary focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white",
                  {
                    ["text-blue-700"]: blok.role === "company",
                  }
                )}
              >
                Unternehmen
              </Link>
            </div>

            {blok.subtitle && (
              <Headline
                as="h3"
                title={blok.subtitle}
                className="text-center mb-6"
              />
            )}

            {blok.description && (
              <p className="text-lg leading-8 text-gray-600 dark:text-gray-300">
                {blok.description}
              </p>
            )}

            <SignupForm role={blok.role as Role} />

            <Link
              href="/login"
              className="font-semibold text-brand-secondary flex items-center justify-center gap-1 text-sm leading-6"
            >
              Login für bestehende Nutzer
            </Link>
          </div>
        </div>

        <aside>
          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </aside>
      </article>
    </div>
  );
};

export default Signup;
