"use client";

import Link from "next/link";
import { StoryblokStory } from "storyblok-generate-ts";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { CheckSquare, Square } from "lucide-react";
import { clsx } from "clsx";

import type { LessonStoryblok } from "@/component-types-sb";
import { List } from "@/components/ui/List";

type ChapterGroup = { [key: string]: LessonStoryblok[] };

const isStringArray = (array: unknown[]): array is string[] => {
  return array.every((element) => typeof element === "string");
};

const groupByChapter = (
  items: StoryblokStory<LessonStoryblok>[]
): ChapterGroup =>
  items?.reduce((acc: any, item) => {
    const chapter = item.content?.chapter;
    if (chapter) {
      if (!acc[chapter]) {
        acc[chapter] = [];
      }

      acc[chapter].push(item);
    }
    return acc;
  }, {});

type ChaptersProps = {
  items?: (StoryblokStory<LessonStoryblok> | string)[];
  completed?: string[];
  chapter?: string | number;
  uuid?: string;
};

const Chapters = ({
  items = [],
  completed = [],
  chapter,
  uuid,
}: ChaptersProps) => {
  const lessonsByChapter = !isStringArray(items)
    ? groupByChapter(items as StoryblokStory<LessonStoryblok>[])
    : {};

  return Object.entries(lessonsByChapter).map(([name, lessons]) => (
    <Disclosure
      key={name}
      as="div"
      className="mt-2"
      defaultOpen={chapter === name}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-md bg-blue-100 px-4 py-2 text-left text-sm font-medium text-brand-secondary hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
            <span>{name}</span>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-5 w-5 text-brand-secondary`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-2 pb-2 pt-4 text-sm text-gray-800 dark:text-gray-500">
            <List>
              {lessons
                ?.sort((a, b) => a.content?.number - b.content?.number)
                .map((lesson) => (
                  <li
                    key={lesson.id}
                    className={clsx({
                      ["font-normal"]: uuid !== lesson.uuid,
                      ["font-semibold text-gray-800 dark:text-gray-200"]:
                        uuid === lesson.uuid,
                    })}
                  >
                    <Link
                      href={`/${lesson.full_slug}`}
                      className="w-full flex flex-row gap-x-2 items-center justify-between hover:text-brand-secondary dark:hover:text-brand-primary"
                    >
                      <span className="text-brand-secondary p-1 text-xs font-mono dark:text-brand-primary">
                        {lesson.content?.number}
                      </span>
                      <span className="flex-grow">{lesson.name}</span>
                    </Link>

                    {completed?.includes(lesson?.uuid) ? (
                      <CheckSquare
                        size="24"
                        className="flex-none text-green-600"
                      />
                    ) : (
                      <Square
                        size="24"
                        className="flex-none text-gray-200 dark:text-gray-600"
                      />
                    )}
                  </li>
                ))}
            </List>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ));
};

export { Chapters };
