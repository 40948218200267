import Link from "next/link";
import { forwardRef } from "react";
import type { InputHTMLAttributes } from "react";
import { cn } from "@/lib/cn";

type CheckboxProps = {
  id?: string;
  name: string;
  label?: string;
  href?: string;
  text?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, name, label, href, text, ...rest }, ref) => {
    return (
      <div className="flex items-center gap-x-1">
        <div className="flex h-5">
          <input
            id={id}
            aria-describedby="checkbox-text"
            name={name}
            type="checkbox"
            ref={ref}
            value=""
            className={cn(
              "w-4 h-4 text-brand-secondary bg-gray-100 border-gray-300 rounded focus:bg-brand-secondary dark:bg-gray-800 dark:border-gray-700",
              rest.className
            )}
            {...rest}
          />
        </div>
        <div className="ms-2 text-sm">
          <label
            htmlFor={id}
            className="font-medium text-gray-900 dark:text-gray-100"
          >
            {href ? (
              <Link
                href={href}
                className="inline-flex py-4 lg:py-2 gap-1 uppercase text-xs font-semibold text-gray-400 hover:text-brand-secondary dark:text-gray-600"
                target="_blank"
              >
                {label}
              </Link>
            ) : (
              <>{label}</>
            )}
          </label>
          <p
            id="checkbox-text"
            className="text-xs font-normal text-gray-500 dark:text-gray-300"
          >
            {text}
          </p>
        </div>
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export { Checkbox };
