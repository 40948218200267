import Image from "next/image";

import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Icon, type IconName } from "@/components/typography/Icon";
import { LinkWrapper } from "@/components/utils/LinkWrapper";
import type { FeatureItemStoryblok, CtaStoryblok } from "@/component-types-sb";

type FeatureItemProps = {
  blok: FeatureItemStoryblok;
};

const FeatureItem = ({ blok }: FeatureItemProps) => (
  <div {...storyblokEditable(blok)} className="flex flex-col gap-4">
    <LinkWrapper link={blok.link} className="relative">
      {blok.icon && (
        <Icon
          name={blok.icon as IconName}
          className="w-16 h-16 mx-auto text-brand-primary mb-4"
        />
      )}

      {blok.image?.filename && (
        <Image
          src={blok.image.filename}
          alt={blok.image.alt || ""}
          className="w-full h-64 rounded-md object-cover mb-4"
          width={400}
          height={200}
        />
      )}

      {blok.name && (
        <h3 className="text-lg md:text-xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
          {blok.name}
        </h3>
      )}
      <div className="mt-2 font-semibold italic tracking-tight text-gray-900 dark:text-gray-100">
        {blok.subline}
      </div>
      <p className="mt-2 text-gray-600 dark:text-gray-300">{blok.text}</p>
    </LinkWrapper>

    {blok.cta?.map((nestedBlok: CtaStoryblok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </div>
);

export default FeatureItem;
