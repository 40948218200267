import Link from "next/link";
import { CheckIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";
import { storyblokEditable } from "@storyblok/react/rsc";

import { Tagline } from "@/components/typography/Tagline";
import { Badge } from "@/components/ui/Badge";
import { formatPrice } from "@/lib/utils/number";
import type { PricingStoryblok } from "@/component-types-sb";

type PricingPops = {
  blok: PricingStoryblok;
};

const Pricing = ({ blok }: PricingPops) => (
  <div className="py-24" {...storyblokEditable(blok)}>
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-4xl text-center">
        <Tagline text="Sicheres bezahlen" />

        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-gray-100">
          {blok.headline}
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600 dark:text-gray-400">
        {blok.text}
      </p>

      <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {blok.plans?.map((plan: any, index: number) => (
          <div
            key={plan.uuid}
            className={clsx(
              plan.content.highlight ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
              index === 0 ? "lg:rounded-r-none" : "",
              index === blok.plans.length - 1 ? "lg:rounded-l-none" : "",
              "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 dark:bg-black dark:ring-gray-800"
            )}
          >
            <div>
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={plan.id}
                  className={clsx(
                    plan.content.highlight
                      ? "text-brand-secondary"
                      : "text-gray-900 dark:text-gray-100",
                    "text-lg font-semibold leading-8"
                  )}
                >
                  {plan.content.name}
                </h3>
                {plan.content.highlight && <Badge text="Beliebt" />}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600 dark:text-gray-400">
                {plan.content.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                  {plan.content.price
                    ? formatPrice(plan.content.price)
                    : "individuell"}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600 dark:text-gray-400">
                  / {plan.content.period}
                </span>
              </p>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600 dark:text-gray-400"
              >
                {plan.content.features?.map((feature: string) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-brand-secondary"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <Link
              href={plan.full_slug}
              aria-describedby={plan.id}
              className={clsx(
                plan.content.highlight
                  ? "bg-brand-secondary text-white shadow-sm hover:bg-blue-500"
                  : "text-brand-secondary ring-1 ring-inset ring-blue-200 hover:ring-blue-300 dark:text-white",
                "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              )}
            >
              Auswählen
            </Link>
          </div>
        ))}
      </div>

      {blok.callback && (
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600 dark:text-gray-400">
          <a
            href="https://www.calendly.com/ivo-rettig/kennenlerngespraech"
            target="_blank"
          >
            Ich will erst ein kostenloses Beratungsgespräch mit Ivo.
          </a>
        </p>
      )}
    </div>
  </div>
);

export default Pricing;
