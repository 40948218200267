import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { cn } from "@/lib/cn";

import type { GridStoryblok } from "@/component-types-sb";

type GridProps = {
  blok: GridStoryblok;
};

const Grid = ({ blok }: GridProps) => (
  <section {...storyblokEditable(blok)}>
    <div className="mx-auto max-w-7xl" {...storyblokEditable(blok)}>
      <div
        className={cn("grid grid-cols-1 md:grid-cols-3 gap-4 my-8 text-left", {
          ["grid-cols-1 md:grid-cols-2"]: blok.columns?.length === 2,
          ["grid-cols-2 md:grid-cols-4"]: blok.columns?.length === 4,
        })}
      >
        {blok?.columns?.map((nestedBlok: any) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            style={blok.style}
          />
        ))}
      </div>
    </div>
  </section>
);

export default Grid;
