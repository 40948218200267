"use client";

import Link from "next/link";
import { Fragment, useEffect } from "react";
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/nextjs";
import { PublicUserData } from "@clerk/types";
import { StoryblokComponent, ISbStoryData } from "@storyblok/react/rsc";
import { usePostHog } from "posthog-js/react";

import { Disclosure, Menu, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  EllipsisVerticalIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import type { MenuItemStoryblok } from "@/component-types-sb";

import { LibraryBig } from "lucide-react";
import { clsx } from "clsx";

import { Logo } from "@/components/typography/Logo";
import { Button } from "@/components/ui/Button";
import { SearchInput } from "@/components/form/SearchInput";
import { useConsent } from "@/lib/hooks/useConsent";

type NavigationProps = {
  menu: ISbStoryData<MenuItemStoryblok> | null;
  user?: PublicUserData | null;
};

const Navigation = ({ menu }: NavigationProps) => {
  const { statistics } = useConsent();
  const posthog = usePostHog();

  useEffect(() => {
    // see https://posthog.com/tutorials/delayed-survey
    const timeoutId = setTimeout(() => {
      const nav = document.querySelector("nav");
      if (nav && statistics) {
        nav.classList.add("delayed-survey");
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [statistics]);

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-400"
                  aria-label="Menu ein-/ausblenden"
                >
                  <span className="sr-only">Menu anzeigen</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <Link
                  href="/"
                  className="flex-shrink-0 flex items-center cursor-pointer"
                >
                  <Logo className="w-auto h-7 text-brand-primary" />
                  <span className="sr-only">Dialetics</span>
                </Link>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex flex-wrap gap-x-4 items-center">
                    <Popover.Group className="flex gap-x-4 items-center">
                      {menu?.content?.items?.map(
                        (nestedBlok: MenuItemStoryblok) => (
                          <StoryblokComponent
                            blok={nestedBlok}
                            key={`desktop-${nestedBlok._uid}`}
                          />
                        )
                      )}
                    </Popover.Group>
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 -right-2 space-x-2 items-center pr-0 sm:static sm:inset-auto sm:ml-6 sm:pr-2 hidden md:flex">
                <form
                  method="get"
                  action="/search"
                  className="pr-3 justify-end"
                >
                  <SearchInput name="query" label="Suche" size="small" />
                </form>
                <SignedIn>
                  <Link href="/library">
                    <Button
                      variant="secondary"
                      size="small"
                      label="Alle Kurse"
                      icon={<LibraryBig size={16} />}
                    />
                  </Link>
                </SignedIn>
                <SignedOut>
                  <SignInButton mode="modal">
                    <Button
                      variant="neutral"
                      size="small"
                      label="Alle Kurse"
                      icon={<LibraryBig size={16} />}
                    />
                  </SignInButton>
                </SignedOut>
                <SignedIn>
                  <UserButton />

                  <Menu as="div" className="ml-3 relative z-10">
                    <div>
                      <Menu.Button className="text-gray-600 hover:bg-gray-100 px-3 py-1.5 font-medium flex text-xs rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-primary focus:ring-white dark:hover:bg-gray-800">
                        <EllipsisVerticalIcon className="h-6 w-6" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-sm py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:text-gray-400 dark:bg-black">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/profile"
                              className={clsx(
                                active
                                  ? "bg-gray-100 dark:hover:bg-gray-900"
                                  : "",
                                "block px-4 py-2 text-sm text-gray-700 dark:text-gray-100"
                              )}
                            >
                              Profil
                            </Link>
                          )}
                        </Menu.Item>
                        {/* <Menu.Item>
                        {({ active }) => (
                          <Link
                            href="/upload"
                            className={clsx(
                              active
                                ? "bg-gray-100 dark:hover:bg-gray-900"
                                : "",
                              "block px-4 py-2 text-sm text-gray-700 dark:text-gray-100"
                            )}
                          >
                            Video Upload
                          </Link>
                        )}
                      </Menu.Item> */}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </SignedIn>

                <SignedOut>
                  <Link href="/onboarding/user">
                    <Button
                      variant="secondary"
                      size="small"
                      label="Registrieren"
                      onClick={() => posthog.capture("user signup")}
                      icon={
                        <UserCircleIcon
                          className="block h-6 w-6 sm:hidden"
                          aria-hidden="true"
                        />
                      }
                    />
                  </Link>
                </SignedOut>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            {({ close }) => (
              <>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {menu?.content?.items?.map(
                    (nestedBlok: MenuItemStoryblok) => (
                      <StoryblokComponent
                        blok={nestedBlok}
                        key={`mobile-${nestedBlok._uid}`}
                        className="block text-base"
                        onClick={close}
                        mobile
                      />
                    )
                  )}
                  <Link
                    href="/library"
                    className="block text-base px-3 py-2 font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800"
                  >
                    Alle Kurse
                  </Link>

                  <SignedOut>
                    <Link
                      href="/onboarding/user"
                      className="block text-base px-3 py-2 "
                    >
                      <Button
                        variant="secondary"
                        size="small"
                        label="Registrieren"
                        onClick={() => {
                          posthog.capture("user signup");
                          close();
                        }}
                        icon={
                          <UserCircleIcon
                            className="block h-6 w-6 sm:hidden"
                            aria-hidden="true"
                          />
                        }
                      />
                    </Link>
                  </SignedOut>
                </div>
              </>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export { Navigation };
