import type { ContactStoryblok } from "@/component-types-sb";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";

type ContactProps = {
  blok: ContactStoryblok;
};

const Contact = ({ blok }: ContactProps) => {
  return (
    <article {...storyblokEditable(blok)} className="w-full">
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </article>
  );
};

export default Contact;
