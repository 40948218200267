import Link from "next/link";
import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  SVGProps,
  RefAttributes,
} from "react";
import type { HTMLAttributes } from "react";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { cn } from "@/lib/cn";
import { Icon, type IconName } from "@/components/typography/Icon";
import type { MenuItem, CallToAction } from "./Menu";

type Icon = ForwardRefExoticComponent<
  PropsWithoutRef<SVGProps<SVGSVGElement>> & RefAttributes<SVGSVGElement>
>;

type MobileMenuProps = {
  name: string;
  menu: MenuItem[];
  callsToAction: CallToAction[];
} & HTMLAttributes<HTMLAnchorElement>;

const MobileMenu = ({
  name,
  menu = [],
  callsToAction = [],
  ...rest
}: MobileMenuProps) => (
  <Disclosure
    as="div"
    className="text-gray-600 px-3 py-2 font-semibold dark:text-gray-400 dark:hover:bg-gray-800"
  >
    {({ open }: { open: boolean }) => (
      <>
        <Disclosure.Button className="inline-flex items-center gap-x-1">
          <span>{name}</span>
          <ChevronDownIcon
            className={cn("h-5 w-5", {
              ["rotate-180 transform"]: open,
            })}
            aria-hidden="true"
          />
        </Disclosure.Button>

        <Disclosure.Panel className="mt-2 space-y-2">
          {[...menu, ...callsToAction].map((item) => (
            <Disclosure.Button
              key={item.name}
              className="rounded-md w-full flex font-normal p-2 hover:bg-gray-50 dark:hover:bg-gray-900"
            >
              <Link
                href={item.href}
                className="flex gap-x-4"
                onClick={rest.onClick}
              >
                {item.icon && (
                  <Icon
                    name={item.icon as IconName}
                    className="h-4 w-4 text-gray-600 group-hover:text-brand-secondary dark:group-hover:text-brand-primary"
                    aria-hidden="true"
                  />
                )}
                {item.name}
              </Link>
            </Disclosure.Button>
          ))}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export { MobileMenu };
