import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";

import type { AssetLinkStoryblok } from "@/component-types-sb";

type AssetLinkProps = {
  blok: AssetLinkStoryblok;
};

const AssetLink = ({ blok }: AssetLinkProps) => (
  <Link
    href={blok.link?.cached_url || "#"}
    aria-label="Seite öffnen"
    {...storyblokEditable(blok)}
  >
    {blok.asset && (
      <Image
        src={blok.asset.filename}
        alt={blok.asset.alt || ""}
        className="w-32"
        width={128}
        height={128}
      />
    )}
  </Link>
);

export default AssetLink;
