import type { HTMLAttributes } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { cn } from "@/lib/cn";

type Variant = "info" | "success" | "error";

type AlertProps = {
  text: string;
  variant?: Variant;
} & HTMLAttributes<HTMLDivElement>;

const Alert = ({ text, variant = "info", ...rest }: AlertProps) => {
  if (!text) {
    return null;
  }

  return (
    <div
      role="alert"
      className={cn(
        "flex items-center space-x-2 p-4 mb-4 text-sm font-semibold rounded-lg border",
        {
          ["bg-brand-secondary/5 text-brand-secondary border-brand-secondary dark:bg-brand-secondary/50 dark:text-blue-100"]:
            variant === "info",
          ["bg-green-600/5 text-green-600 border-green-600 dark:bg-green-600/50 dark:text-green-100"]:
            variant === "success",
          ["bg-red-600/5 text-red-600 border-red-600 dark:bg-red-600/50 dark:text-red-100"]:
            variant === "error",
        },
        rest.className
      )}
    >
      <InformationCircleIcon
        className={cn("w-6 h-6", {
          ["text-brand-secondary"]: variant === "info",
          ["text-green-600"]: variant === "success",
          ["text-red-600"]: variant === "error",
        })}
      />
      <span className="sr-only">Info</span>
      <div>{text}</div>
    </div>
  );
};

export { Alert };
