import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";

import type { ImageListStoryblok } from "@/component-types-sb";

type ImageListProps = {
  blok: ImageListStoryblok;
};

const ImageList = ({ blok }: ImageListProps) => (
  <div
    {...storyblokEditable(blok)}
    className="md:max-w-7xl md:mx-auto my-16 px-6"
  >
    <div className="grid grid-cols-3 sm:grid-cols-6 gap-8 items-center">
      {blok.images?.map((image) => (
        <Link href="#" target="_blank" key={image.id}>
          <Image
            src={image.filename}
            alt={image.alt || ""}
            className="w-48"
            width={900}
            height={641}
          />
        </Link>
      ))}
    </div>
  </div>
);

export default ImageList;
