import type { ComponentProps, ReactNode } from "react";
import {
  AcademicCapIcon,
  AtSymbolIcon,
  CheckIcon,
  ClockIcon,
  PlusIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import {
  ChatBubbleLeftIcon,
  HomeIcon,
  MicrophoneIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import {
  CalendarIcon,
  DumbbellIcon,
  NewspaperIcon,
  PersonStanding,
  Plane,
  LibraryBig,
} from "lucide-react";
import { cn } from "@/lib/cn";

import { SvgIcon } from "./SvgIcon";
import type { SvgIconName } from "./SvgIcon";

export const icons = [
  "AcademicCapIcon",
  "AtSymbolIcon",
  "CalendarIcon",
  "ChatBubbleLeftIcon",
  "CheckIcon",
  "ClockIcon",
  "Coaching",
  "Dexcom",
  "Diary",
  "DumbbellIcon",
  "Healthcare",
  "HomeIcon",
  "IceCream",
  "Insulin",
  "Insurance",
  "LibraryBig",
  "Memories",
  "Measurement",
  "MicrophoneIcon",
  "NewspaperIcon",
  "PersonStanding",
  "Plane",
  "PlayIcon",
  "PlusIcon",
  "Relax",
  "Tips",
  "Trust",
  "UserGroupIcon",
  "Pregnant",
  "Children",
  "Athlete",
  "Yoga",
  "TwoPeople",
  "ThreePeople",
] as const;

type IconName = (typeof icons)[number] | SvgIconName;

type IconComponentProps = {
  name?: IconName;
  className?: string;
};

const IconComponent = ({ name, className }: IconComponentProps): ReactNode => {
  switch (name) {
    case "CheckIcon":
      return <CheckIcon className={className} />;
    case "ClockIcon":
      return <ClockIcon className={className} />;
    case "PlusIcon":
      return <PlusIcon className={className} />;
    case "AcademicCapIcon":
      return <AcademicCapIcon className={className} />;
    case "AtSymbolIcon":
      return <AtSymbolIcon className={className} />;
    case "UserGroupIcon":
      return <UserGroupIcon className={className} />;
    case "HomeIcon":
      return <HomeIcon className={className} />;
    case "PlayIcon":
      return <PlayIcon className={className} />;
    case "ChatBubbleLeftIcon":
      return <ChatBubbleLeftIcon className={className} />;
    case "MicrophoneIcon":
      return <MicrophoneIcon className={className} />;
    case "Plane":
      return <Plane className={className} />;
    case "LibraryBig":
      return <LibraryBig className={className} />;
    case "PersonStanding":
      return <PersonStanding className={className} />;
    case "DumbbellIcon":
      return <DumbbellIcon className={className} />;
    case "CalendarIcon":
      return <CalendarIcon className={className} />;
    case "NewspaperIcon":
      return <NewspaperIcon className={className} />;
    case "Insulin":
      return <SvgIcon name={name} className={className} />;
    case "Measurement":
      return <SvgIcon name={name} className={className} />;
    case "Healthcare":
      return <SvgIcon name={name} className={className} />;
    case "Dexcom":
      return <SvgIcon name={name} className={className} />;
    case "Diary":
      return <SvgIcon name={name} className={className} />;
    case "Coaching":
      return <SvgIcon name={name} className={className} />;
    case "Muscle":
      return <SvgIcon name={name} className={className} />;
    case "Happy":
      return <SvgIcon name={name} className={className} />;
    case "IceCream":
      return <SvgIcon name={name} className={className} />;
    case "Insurance":
      return <SvgIcon name={name} className={className} />;
    case "Relax":
      return <SvgIcon name={name} className={className} />;
    case "Memories":
      return <SvgIcon name={name} className={className} />;
    case "Trust":
      return <SvgIcon name={name} className={className} />;
    case "Tips":
      return <SvgIcon name={name} className={className} />;
    case "Pregnant":
      return <SvgIcon name={name} className={className} />;
    case "Children":
      return <SvgIcon name={name} className={className} />;
    case "Athlete":
      return <SvgIcon name={name} className={className} />;
    case "Yoga":
      return <SvgIcon name={name} className={className} />;
    case "TwoPeople":
      return <SvgIcon name={name} className={className} />;
    case "ThreePeople":
      return <SvgIcon name={name} className={className} />;
    default:
      return null;
  }
};

type IconProps = {
  name: IconName;
} & ComponentProps<"svg">;

const Icon = ({ name, ...rest }: IconProps) => (
  <IconComponent name={name} className={cn("h-5 w-5", rest.className)} />
);

export { Icon };
export type { IconName };
