import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

import { RichText } from "@/components/typography/RichText";
import type { FaqItemStoryblok } from "@/component-types-sb";

type FaqItemProps = {
  blok: FaqItemStoryblok;
};

const FaqItem = ({ blok }: FaqItemProps) => (
  <Disclosure as="div" {...storyblokEditable(blok)} className="pt-3">
    {({ open }) => (
      <>
        <dt>
          <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-base font-semibold leading-7">
              {blok.name}
            </span>
            <span className="ml-6 flex h-7 items-center">
              {open ? (
                <MinusIcon className="h-6 w-6" aria-hidden="true" />
              ) : (
                <PlusIcon className="h-6 w-6" aria-hidden="true" />
              )}
            </span>
          </Disclosure.Button>
        </dt>
        <Disclosure.Panel as="dd" className="mt-2 pr-12">
          <RichText
            html={renderRichText(blok.text)}
            className="text-base leading-7 text-gray-600"
          />
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default FaqItem;
