import Image from "next/image";
import type { ComponentProps } from "react";
import { UserIcon } from "@heroicons/react/24/solid";
import { cn } from "@/lib/cn";

type Size = "small" | "medium" | "large";

type AvatarProps = {
  src?: string | null | undefined;
  size?: Size;
  rounded?: boolean;
  logo?: boolean;
} & Omit<ComponentProps<"img">, "src">;

const Avatar = ({
  src,
  size = "medium",
  rounded = true,
  logo,
  ...rest
}: AvatarProps) => {
  src = src ? src : logo ? "/d.svg" : null;

  const classOptions = {
    ["w-7 h-7"]: size === "small",
    ["w-10 h-10"]: size === "medium",
    ["w-16 h-16"]: size === "large",
    ["rounded-full"]: rounded,
    ["rounded-none"]: logo,
  };

  return src ? (
    <Image
      src={src}
      width="64"
      height="64"
      alt=""
      className={cn(
        "object-cover aspect-square",
        {
          ...classOptions,
        },
        rest.className
      )}
    />
  ) : (
    <div
      className={cn(
        "flex items-center justify-center bg-gray-200 dark:bg-gray-700",
        {
          ...classOptions,
        },
        rest.className
      )}
      aria-hidden="true"
    >
      <UserIcon className="h-full w-full text-gray-400 m-1" />
    </div>
  );
};

export { Avatar };
