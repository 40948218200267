"use client";

import { useSearchParams } from "next/navigation";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

import { Tag as TagComponent } from "@/components/ui/Tag";
import { Spinner } from "@/components/ui/Spinner";
import { getTags } from "@/lib/storyblok/getTags";

type Tag = {
  id: number;
  name: string;
  value: string;
};

const Tags = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const query = searchParams.get("tag") || undefined;

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTags();
      setTags(data as Tag[]);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="pb-12 max-w-7xl mx-auto px-6 lg:px-8">
      <div className="flex items-start justify-center">
        {isLoading && <Spinner />}

        {!isLoading &&
          tags?.map((tag) => (
            <TagComponent
              key={tag.id}
              name={tag.name}
              href={query === tag.value ? pathname : `?tag=${tag.value}`}
              active={query === tag.value}
            />
          ))}
      </div>
    </div>
  );
};

export { Tags };
