import { createElement } from "react";
import { cn } from "@/lib/cn";

type As = "p" | "span" | "div" | "label";

type BodyTextProps = {
  as?: As;
} & JSX.IntrinsicElements[As];

const BodyText = ({ as = "p", className, ...rest }: BodyTextProps) =>
  createElement(as, {
    className: cn("text-lg mt-4", className),
    ...rest,
  });

export { BodyText };
