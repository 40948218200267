export const formatFileSize = (size: number) =>
  Intl.NumberFormat("de-DE", {
    notation: "compact",
    style: "unit",
    unit: "megabyte",
    unitDisplay: "narrow",
  }).format(size / 1000 / 1000);

export const formatPrice = (
  number?: number | string,
  options?: Intl.NumberFormatOptions
) => {
  number = Number(number);

  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  }).format(number);
};

export const formatNumber = (
  number?: number,
  options?: Intl.NumberFormatOptions
) => {
  if (!number || isNaN(number)) {
    return null;
  }
  return new Intl.NumberFormat("de-DE", {
    maximumSignificantDigits: 3,
    ...options,
  }).format(number);
};

export const formatPercent = (
  number?: number,
  options?: Intl.NumberFormatOptions
) => {
  if (!number || isNaN(number)) {
    return null;
  }
  return new Intl.NumberFormat("de-DE", {
    style: "percent",
    ...options,
  }).format(number);
};
