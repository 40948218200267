import { useAuth } from "@clerk/nextjs";
import { renderRichText } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { clsx } from "clsx";
import dynamic from "next/dynamic";
import Link from "next/link";

import type { TextVideoStoryblok } from "@/component-types-sb";
import { RichText } from "@/components/typography/RichText";
import { Button } from "@/components/ui/Button";
import { VideoSkeleton } from "@/components/ui/Skeleton";
import { useConsent } from "@/lib/hooks/useConsent";

const MuxPlayer = dynamic(() => import("@mux/mux-player-react"), {
  ssr: false,
  loading: () => <VideoSkeleton className="w-full h-full" />,
});

type TextVideoProps = { blok: TextVideoStoryblok };

const TextVideo = ({ blok }: TextVideoProps) => {
  const { userId } = useAuth();
  const { statistics } = useConsent();

  return (
    <section
      {...storyblokEditable(blok)}
      className={clsx({
        ["bg-gray-100 dark:bg-gray-800"]: blok.background === "gray",
      })}
    >
      <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <div className="my-8 md:my-0">
          <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-800 dark:text-gray-100">
            {blok.headline}
          </h2>
          <p className="mb-6 md:text-lg text-gray-800 dark:text-gray-100">
            {blok.subheadline}
          </p>
          <RichText html={renderRichText(blok.text)} />

          {blok.link?.cached_url && (
            <Link href={blok.link.cached_url}>
              <Button label={blok.button || "Mehr Infos"} className="mt-4" />
            </Link>
          )}
        </div>

        <MuxPlayer
          streamType="on-demand"
          playbackId={blok.playback_id}
          metadata={{
            video_title: blok.title,
            viewer_user_id: userId,
          }}
          preload="auto"
          className="aspect-video"
          disableCookies={!statistics}
          accentColor="#fbc02d"
        />
      </div>
    </section>
  );
};

export default TextVideo;
