"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  SVGProps,
  RefAttributes,
} from "react";

import { Fragment, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { cn } from "@/lib/cn";
import { Icon, type IconName } from "@/components/typography/Icon";

type Icon = ForwardRefExoticComponent<
  PropsWithoutRef<SVGProps<SVGSVGElement>> & RefAttributes<SVGSVGElement>
>;

type MenuItem = {
  name: string;
  description?: string;
  href: string;
  icon?: string;
};

type CallToAction = {
  name: string;
  href: string;
  icon?: string;
};

type MenuProps = {
  name: string;
  menu: MenuItem[];
  callsToAction: CallToAction[];
};

const Menu = ({ name, menu = [], callsToAction = [] }: MenuProps) => {
  const pathname = usePathname();
  const current = (href: string) => href.replace(/\/$/, "") === pathname;

  const timeoutDuration = 120;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const timeOutRef = useRef<NodeJS.Timeout>();

  const handleEnter = (open: boolean) => {
    clearTimeout(timeOutRef.current);
    !open && buttonRef.current?.click();
  };

  const handleLeave = (open: boolean) => {
    timeOutRef.current = setTimeout(() => {
      open &&
        document.activeElement === buttonRef.current &&
        buttonRef.current?.click();
    }, timeoutDuration);
  };

  return (
    <Popover className="relative text-gray-600 hover:bg-gray-100 px-3 py-2 rounded-md text-sm font-medium dark:text-gray-400 dark:hover:bg-gray-800">
      {({ open }) => (
        <div
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
        >
          <Popover.Button
            ref={buttonRef}
            className="inline-flex items-center gap-x-1"
          >
            <span>{name}</span>
            <ChevronDownIcon
              className="h-5 w-5 ui-open:rotate-180 ui-open:transform"
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4">
              {({ close }) => (
                <>
                  <div className="w-screen max-w-md flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow-sm ring-1 ring-gray-900/5 dark:bg-black dark:ring-gray-100/5">
                    <div className="p-4">
                      {menu.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-md p-1 hover:bg-gray-50 dark:hover:bg-gray-900"
                        >
                          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-md bg-gray-50 group-hover:bg-white dark:bg-gray-950 dark:group-hover:bg-black">
                            {item.icon && (
                              <Icon
                                name={item.icon as IconName}
                                className="h-6 w-6 text-gray-600 group-hover:text-brand-secondary dark:group-hover:text-brand-primary"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                          <div>
                            <Link
                              href={item.href}
                              onClick={() => close()}
                              className={cn(
                                "font-semibold text-gray-900 dark:text-gray-100",
                                {
                                  ["text-brand-primary dark:text-brand-primary"]:
                                    current(item.href),
                                }
                              )}
                              aria-current={
                                current(item.href) ? "page" : undefined
                              }
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </Link>
                            <p className="mt-1 text-gray-600">
                              {item.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className={cn(
                        "grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 dark:bg-gray-950 dark:divide-white/5",
                        {
                          ["grid-cols-1"]: callsToAction.length === 1,
                        }
                      )}
                    >
                      {callsToAction.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          onClick={() => close()}
                          className={cn(
                            "flex items-center justify-center gap-x-2.5 p-3 font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-900",
                            {
                              ["text-brand-primary dark:text-brand-primary"]:
                                current(item.href),
                            }
                          )}
                          aria-current={current(item.href) ? "page" : undefined}
                        >
                          {item.icon && (
                            <Icon
                              name={item.icon as IconName}
                              className="h-5 w-5 flex-none text-gray-400 dark:text-gray-600"
                              aria-hidden="true"
                            />
                          )}
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

export { Menu };
export type { MenuItem, CallToAction };
