import type { ComponentPropsWithoutRef } from "react";
import { cn } from "@/lib/cn";

type Props = ComponentPropsWithoutRef<"div"> & {
  html: string;
};

const RichText = ({ html, ...rest }: Props) => (
  <div
    className={cn(
      "break-words hyphens-auto prose text-base text-gray-800 max-w-4xl prose-ul:list-image-checkmark [&>ul>li>p]:my-2 prose-a:text-brand-secondary prose-a:font-normal prose-a:underline hover:prose-a:no-underline prose-a:decoration-2 prose-a:decoration-brand-secondary dark:text-gray-200 prose-a:dark:text-white prose-headings:dark:text-white prose-blockquote:p-1 prose-blockquote:px-4 prose-blockquote:bg-gray-50 prose-blockquote:leading-relaxed",
      rest.className
    )}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);

export { RichText };
