import Link from "next/link";
import { usePathname } from "next/navigation";
import type { HTMLAttributes } from "react";
import { storyblokEditable } from "@storyblok/react";

import { Menu } from "@/components/layout/Menu";
import { MobileMenu } from "@/components/layout/MobileMenu";
import type {
  MenuItem as MenuItemType,
  CallToAction,
} from "@/components/layout/Menu";
import type {
  MenuItemStoryblok,
  MultilinkStoryblok,
} from "@/component-types-sb";
import { cn } from "@/lib/cn";

type MenuItemProps = HTMLAttributes<HTMLElement> & {
  blok: MenuItemStoryblok;
  mobile?: boolean;
} & HTMLAttributes<HTMLAnchorElement>;

const addLeadingSlash = (link?: MultilinkStoryblok) => {
  const url = link?.cached_url || "";
  return link?.linktype === "story" ? "/" + url : url;
};

const MenuItem = ({ blok, mobile = false, ...rest }: MenuItemProps) => {
  const pathname = usePathname();
  const current =
    blok.link?.cached_url?.replace(/\//, "") === pathname.replace(/\//, "");

  const submenu = blok.submenu?.map(({ name, description, icon, link }) => ({
    name,
    description,
    icon,
    href: addLeadingSlash(link),
  })) as MenuItemType[];

  const ctas = blok.cta?.map(({ name, icon, link }) => ({
    name,
    icon,
    href: addLeadingSlash(link),
  })) as CallToAction[];

  return submenu.length ? (
    mobile ? (
      <MobileMenu
        name={blok.name}
        menu={submenu}
        callsToAction={ctas}
        onClick={rest.onClick}
      />
    ) : (
      <Menu name={blok.name} menu={submenu} callsToAction={ctas} />
    )
  ) : (
    <Link
      {...storyblokEditable(blok)}
      key={blok.name}
      href={`/${blok.link?.cached_url || "#"}`}
      className={cn(
        current
          ? "text-brand-primary dark:text-brand-primary"
          : "text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800",
        "px-3 py-2 text-sm font-medium",
        rest.className
      )}
      aria-current={current ? "page" : undefined}
      onClick={rest.onClick}
    >
      {blok.name}
    </Link>
  );
};

export default MenuItem;
